.NavList-icon {
  width: 22px;
  position: absolute;
  right: 64px;
}
.CourseLister .Button--block {
  width: auto;
}

.umbraco-forms-form {
  padding: 10px 0;
}

.umbraco-forms-caption {
  font-size: 2em !important;
  line-height: 2em !important;
}

.umbraco-forms-field {
  clear: both;
}

.umbraco-forms-field div label {
  display: inline;
}

label.umbraco-forms-label {
  font-weight: 700;
  display: block;
  background: 0 0 !important;
}

.umbraco-forms-form small {
  display: block;
  float: left;
  clear: both;
  padding: 5px 5px 5px 200px;
}

.umbraco-forms-form fieldset {
  padding: 1em 1em 0 0;
  margin: 0 0 1.5em;
  border: none !important;
}

.umbraco-forms-form .umbraco-forms-navigation {
  padding: 1em 0;
}

.umbraco-forms-form legend {
  font-weight: 700;
  font-size: 1.2em;
  line-height: 1.2em;
  display: block;
}

.umbraco-forms-form input.text,
.umbraco-forms-form input.title,
.umbraco-forms-form select,
.umbraco-forms-form textarea {
  margin: 0.5em 0;
  border: 1px solid #bbb;
}

.umbraco-forms-form input.text:focus,
.umbraco-forms-form input.title:focus,
.umbraco-forms-form select:focus,
.umbraco-forms-form textarea:focus {
  border: 1px solid #666;
}

.umbraco-forms-form input.text,
.umbraco-forms-form textarea {
  max-width: 400px !important;
  padding: 5px;
  display: block;
  width: 95%;
}

.umbraco-forms-form textarea {
  height: 250px;
}

.umbraco-forms-form input.fileupload {
  height: auto !important;
}

.umbraco-forms-form span.checkbox,
.umbraco-forms-form span.checkboxlist,
.umbraco-forms-form span.radiobuttonlist {
  display: block;
  float: left;
  padding: 10px;
}

.umbraco-forms-form .checkboxlist label {
  margin-left: 10px;
  clear: left;
}

.umbraco-forms-form .checkbox input,
.umbraco-forms-form .checkboxlist input,
.umbraco-forms-form .radiobuttonlist input {
  width: auto !important;
  height: auto !important;
  border: none !important;
  display: inline !important;
}

.umbraco-forms-form .hiddenfield {
  display: none;
}

.umbraco-forms-button {
  margin-right: 10px;
  padding: 2px 10px;
}

.umbraco-forms-error-message {
  padding: 0.8em;
  margin-bottom: 0.5em;
  border: 2px solid #fbc2c4;
  background: #fbe3e4;
  color: #8a1f11;
}

.umbraco-forms-form input.contourError,
.umbraco-forms-form input.input-validation-error,
.umbraco-forms-form textarea.contourError,
.umbraco-forms-form textarea.input-validation-error {
  background: #fbe3e4;
  border-color: #fbc2c4;
}

.umbraco-forms-form span.contourError,
.umbraco-forms-form span.field-validation-error {
  color: #8a1f11 !important;
  background: 0 0 !important;
}

.umbraco-forms-form #recaptcha_widget_div {
  margin-left: 200px;
}

.umbraco-forms-form .field-validation-error {
  padding-left: 5px;
}

.chosenevents {
  display: none;
}

.ccirsvp input[type='submit'] {
  background-color: #5dbec5;
  color: #fff;
  padding: 10px 20px;
}

#eventchooseerror {
  float: left;
  clear: both;
}
