.Grid {
  $comp: &;

  display: grid;
  grid-gap: $grid-gap;
  
  @include fluid-prop( row-gap, $grid-gap * 1.5, $grid-gap * 3 );

  @media print {
    grid-template-columns: 1fr 1fr;
  }

  &-item {
    & > *:last-child {
      margin-bottom: 0;
    }
  }

  // Modifiers

  &--spaced {
    @include media-breakpoint-up(medium) {
      grid-gap: $grid-gap * 3;
    }
  }

  &--autoflow {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(12, 1fr);
      grid-auto-flow: dense;
    }

    @media print {
      grid-template-columns: repeat(12, 1fr);
      grid-auto-flow: dense;
    }
  }

  &--2col {
    @include media-breakpoint-up(small) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--3col {
    @include media-breakpoint-up(small) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(large) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &--4col {
    @include media-breakpoint-up(small) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(large) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  // Small

  &--sm2 {
    @include media-breakpoint-up(small) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--sm3 {
    @include media-breakpoint-up(small) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &--sm4 {
    @include media-breakpoint-up(small) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  // Medium

  &--md2 {
    @include media-breakpoint-up(medium) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--md3 {
    @include media-breakpoint-up(medium) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &--md4 {
    @include media-breakpoint-up(medium) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  // Large

  &--lg2 {
    @include media-breakpoint-up(large) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--lg3 {
    @include media-breakpoint-up(large) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &--lg4 {
    @include media-breakpoint-up(large) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  // Bordered

  &--separator {
    #{$comp}-item {
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: $border-color;
        left: -$grid-gap / 2;
        top: 0;
        position: absolute;
      }
    }

    &#{$comp}--spaced {
      @include media-breakpoint-up(medium) {
        #{$comp}-item::before {
          left: -($grid-gap * 3) / 2;
        }
      }
    }
  }
}

[class*='Grid-item--sm'],
[class*='Grid-item--md'],
[class*='Grid-item--lg'] {
  grid-column-end: span 12;
  min-width: 0;

  @media print {
    grid-column-end: span 6;
  }
}

@mixin create-grid-items( $breakpoint: large ) {
  @include media-breakpoint-up($breakpoint) {
    @for $i from 1 through 12 {
      .Grid-item--#{map-get($grid-classes, $breakpoint)}#{$i} {
        grid-column-end: span $i;
      }
    }

    @for $i from 1 through 12 {
      .Grid-item--#{map-get($grid-classes, $breakpoint)}Offset#{$i} {
        grid-column-start: $i + 1;
      }
    }
  }
}

@include create-grid-items( small );
@include create-grid-items( medium );
@include create-grid-items( large );
