.CourseCard {
  position: relative;
  background: $color-tint-4;
  border-left: 5px solid $color-highlight-1;
  font-size: $font-size-small;
  margin-bottom: 30px;
  padding: 20px 25px 0;

  &-body {
    @include media-breakpoint-up(medium) {
      padding: 0 250px 0 0;
    }
  }

  &-title {
    font-size: 20px;
  }

  &-operator {
    color: $color-tint-2;
  }

  &-tags {
    @extend %list-unstyled;
    display: flex;
    margin-bottom: 20px;

    li {
      background: #fff;
      border-radius: 2em;
      padding: 1px 15px;
      margin-right: 6px;
    }
  }

  &-footer {
    padding: 15px 25px;
    margin: 25px -25px 0;
    border-top: 1px solid $border-color;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(medium) {
      justify-content: space-between;
    }
  }

  &-meta {
    @extend %list-unstyled;
    
    color: $color-base;
    font-weight: 500;
    margin-bottom: 20px;

    svg {
      color: $color-tint-2;
      margin-right: 4px;
    }

    @include media-breakpoint-up(medium) {
      display: flex;
      margin-bottom: 0;

      li {
        margin-right: 25px;
      }
    }
  }

  &-btnGo {
    @include media-breakpoint-up(medium) {
      position: absolute;
      right: 25px;
      top: 25px;
      width: auto;
    }
  }

  &-btnMore {
    color: $color-highlight-1;
    font-weight: 500;
    font-size: $font-size-base;
  }

  &-info {
    font-size: $font-size-base;
    padding-bottom: 10px;
    margin-top: 20px;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}