.SectionHeader {
  $comp: &;

  margin-bottom: $grid-gap * 2;
  display: grid;
  row-gap: $grid-gap;
  column-gap: $grid-gap;

  @include media-breakpoint-up(medium) {
    grid-template-areas:
      'heading heading'
      'intro control';
    grid-template-columns: 6fr 6fr;
  }

  &-heading {
    border-bottom: 1px solid $border-color;
    margin-bottom: 0;
    margin-left: $page-margin-negative;
    padding-bottom: $grid-gap;
    padding-left: $page-margin-positive;

    @include media-breakpoint-up(medium) {
      grid-area: heading;
      padding-right: percentage(3/12);
    }

    &--h1 {
      @include h1;
    }

    &--h2 {
      @include h2;
    }

    &--h3 {
      @include h3;
    }

    &--meta {
      @extend %Meta;
    }
  }

  &-lead {
    margin-bottom: 0;

    @include media-breakpoint-up(medium) {
      grid-area: intro;
    }
  }

  &-control {
    @include media-breakpoint-up(medium) {
      place-self: end;
      grid-area: heading;
      width: percentage(2/12);
      padding-bottom: $grid-gap;
      text-align: right;
    }
  }

  // Modifiers

  &--primary {
    @include fluid-prop(margin-bottom, $grid-gap * 2, $grid-gap * 3);

    #{$comp}-heading {
      @include h1;
      margin-bottom: 0;
    }
  }

  &--hasWatermarkLeft,
  &--hasWatermarkRight {
    @include media-breakpoint-up(medium) {
      position: relative;

      &::before {
        @include fluid-prop( width, 320px, 800px );
        @include fluid-prop( height, 198px, 493px );

        background: url('/src/assets/cci-watermark.svg') 0 50% / auto 100% no-repeat;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        z-index: -2;
      }
    }
  }

  &--hasWatermarkLeft::before {
    @include media-breakpoint-up(medium) {
      left: 0;
      transform: translateX(-50%) translateY(-50%) scale(0.75);
    }
  }

  &--hasWatermarkRight::before {
    @include media-breakpoint-up(medium) {
      right: 0;
      transform: translateX(50%) translateY(-50%) scale(0.75);
    }
  }

  &--altLayout {
    border-bottom: 1px solid $border-color;
    padding-bottom: $grid-gap * 1.5;
    margin-bottom: $grid-gap * 1.5;

    @include media-breakpoint-up(medium) {
      row-gap: $grid-gap / 2;
    }

    #{$comp}-heading {
      border-bottom: 0;
      padding-bottom: 0;
    }

    #{$comp}-control {
      @include media-breakpoint-up(medium) {
        grid-area: control;
        width: auto;
        padding-bottom: 0;
      }
    }
  }
}
