.Testimonial {
  $comp: &;

  border-left: 1px solid $border-color;
  margin: 0;
  @include fluid-prop( padding-left, $grid-gap, 70px );

  @media screen {
    border-left-color: $border-color-inverse;
  }

  &-question#{$comp}-question {
    @include fluid-type( 18px, 24px );
    color: $color-tint-2;
  }

  &-quote#{$comp}-quote {
    @include h2;
    color: #fff;
  }

  &-footer {
    align-items: center;
    display: flex;
    margin-top: $grid-gap;
  }

  &-footerImg {
    flex: 0 1;

    @include fluid-prop( flex-basis, 64px, 84px );
    @include fluid-prop( margin-right, $grid-gap, $grid-gap * 2 );
  }

  &-title {
    font-size: 22px;
    font-weight: normal;
    margin: 0 0 0.25em;
  }

  &-subtitle {
    display: block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  &-meta {
    @extend %Meta;
    opacity: 0.3;
    color: inherit;
  }

  // Modifiers

  &--slide {
    border: 0;
    padding-left: 0;

    #{$comp}-quote {
      @include fluid-type( 18px, 24px );
      color: #fff;
    }

    #{$comp}-footer {
      @include fluid-prop( margin-top padding-top, $grid-gap * 1.5,  $grid-gap * 2 );
      border-top: 1px solid $border-color-inverse;

      @include media-breakpoint-up(small) {
        padding-right: 200px;
      }
    }
  }
}

.TestimonialAlt {
  margin: 0;

  @include media-breakpoint-up(small) {
    align-items: center;
    display: flex;
  }

  &-img {
    margin-bottom: $grid-gap;

    @include media-breakpoint-up(small) {
      flex: 0 1 calc(#{percentage(4/12)} - #{$grid-gap});
      margin-bottom: 0;
      margin-right: percentage(60/1080);
    }
  }

  &-body {
    flex: 1 1 50%;
  }

  &-quote {
    @include h2;
  }

  &-footer {
    margin-top: $grid-gap;
  }

  &-title {
    font-size: 22px;
    font-weight: normal;
    margin: 0 0 0.25em;
  }

  &-subtitle {
    color: $color-tint-2;
    display: block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
}
