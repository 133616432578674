.Bio {
  $comp: &;

  display: flex;

  &-imgContainer {
    flex: 0 1 percentage(180/530);
    margin-right: percentage(30/530);
  }

  &-img {
    margin: 0;
  }

  &-body {
    padding: 0 0 $grid-gap;
    border-bottom: 1px solid $border-color;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &-header {
    margin-bottom: $grid-gap;
  }

  &-title {
    font-size: 22px;
    font-weight: normal;
    margin: 0 0 0.25em;
  }

  &-desc {
    font-size: $font-size-small;
  }

  &-meta {
    @extend %Meta;
    margin-bottom: 0;
  }

  &-link {
    color: $color-highlight-1;
    display: inline-block;
    font-size: 20px;
    transition: color 0.3s ease;
    margin-top: auto;

    &:hover,
    &:focus {
      color: $color-highlight-2;
    }
  }

  &-btn {
    margin-top: auto;
  }

  &--primary {
    flex-direction: column;
    min-height: 100%;

    #{$comp}-imgContainer {
      margin-bottom: 20px;
      max-width: 180px;
    }

    #{$comp}-btn {
      align-self: flex-start;
    }
  }

  &--slide {
    @include fluid-prop( padding-left padding-right padding-bottom, $grid-gap, $grid-gap * 4 );
    
    @include media-breakpoint-down(medium) {
      display: block;
      max-height: calc(100vh - #{$grid-gap * 6});
      overflow-y: auto;
    }

    #{$comp}-imgContainer {
      @include media-breakpoint-down(medium) {
        margin-bottom: 20px;
        max-width: 180px;
      }

      @include media-breakpoint-up(medium) {
        flex: 0 1 percentage(250/820);
        margin-right: percentage(60/820);
      }
    }

    #{$comp}-body {
      border-bottom: none;
      padding: 0;
    }

    #{$comp}-header {
      border-bottom: 1px solid $border-color;
      @include fluid-prop( padding-bottom  margin-bottom, $grid-gap, $grid-gap * 1.5 );
    }

    #{$comp}-desc {
      font-size: inherit;

      @include media-breakpoint-up(medium) {
        flex: 1;
        overflow: auto;
        max-height: 250px;
      }
    }
  }
}
