.SearchResult {
  grid-area: body;

  &-title {
    @include h2;
  }

  &-url {
    font-size: $font-size-small;
    color: $color-tint-2;
  }
}
