.Stat {
  $comp: &;
  
  display: flex;
  flex-direction: column;
  margin: 0;

  &-value {
    font-weight: 500;
    line-height: 1.1;
    margin: 0;
    margin-bottom: 0.1em;
    order: -1;
    color: $color-base;

    span {
      @include fluid-type( 50px, 65px );
      letter-spacing: -0.05em;
      margin-right: 5px;
    }

    sub {
      vertical-align: baseline;
      @include fluid-type( 24px, 30px );
    }
  }

  // Modifiers

  &--alt {
    border-top: 1px solid $border-color;
    border-left: 0;
    padding: $grid-gap 0 0;
  }

  &--inverse {
    @media screen {
      border-color: $border-color-inverse;

      &:not(:first-of-type) {
        border-color: $border-color-inverse;
      }

      #{$comp}-value {
        color: #fff;
      }
    }
  }
}
