//
// Typography
//

.u-font-size-sm {
  font-size: $font-size-small;
}

.u-font-size-lg {
  font-size: $font-size-large;
}

.u-columns-2 {
  columns: 2;
  column-gap: $grid-gap;
}

//
// Visibilty
//

.u-hidden-xs-max {
  @include media-breakpoint-down(small) {
    display: none;
  }
}

.u-hidden-sm-max {
  @include media-breakpoint-down(medium) {
    display: none;
  }
}

.u-hidden-md-max {
  @include media-breakpoint-down(large) {
    display: none;
  }
}

.u-hidden-sm-min {
  @include media-breakpoint-up(small) {
    display: none;
  }
}

.u-hidden-md-min {
  @include media-breakpoint-up(medium) {
    display: none;
  }
}

.u-hidden-lg-min {
  @include media-breakpoint-up(large) {
    display: none;
  }
}

.u-hidden-xlg-min {
  @include media-breakpoint-up(xlarge) {
    display: none;
  }
}

// Layout

.u-d-block {
  display: block !important;
}

.u-d-inlineblock {
  display: inline-block !important;
}

//
// Spacing
//

.u-spacer {
  margin: $grid-gap * 2 0;

  &:first-child {
    margin-top: 0;
  }
}

.u-m-0 {
  margin: 0 !important;
}

@for $i from 0 through 6 {
  // Margin top increments of 10
  .u-mt-#{$i * 10} {
    margin-top: ($i * 10px) !important;
  }

  // Margin bottom increments of 10
  .u-mb-#{$i * 10} {
    margin-bottom: ($i * 10px) !important;
  }

  // Margin top increments of 10
  .u-mr-#{$i * 10} {
    margin-right: ($i * 10px) !important;
  }

  // Margin bottom increments of 10
  .u-ml-#{$i * 10} {
    margin-left: ($i * 10px) !important;
  }
}

.u-p-0 {
  padding: 0 !important;
}

@for $i from 0 through 6 {
  // Margin top increments of 10
  .u-pt-#{$i * 10} {
    padding-top: ($i * 10px) !important;
  }

  // Margin bottom increments of 10
  .u-pb-#{$i * 10} {
    padding-bottom: ($i * 10px) !important;
  }

  // Margin top increments of 10
  .u-pr-#{$i * 10} {
    padding-right: ($i * 10px) !important;
  }

  // Margin bottom increments of 10
  .u-pl-#{$i * 10} {
    padding-left: ($i * 10px) !important;
  }
}

//
// Alignment
//

@mixin create-justify-utils( $breakpoint ) {
  $positions: 
    center, 
    start, 
    end, 
    flex-start, 
    flex-end,
    space-between, 
    space-around, 
    space-evenly, 
    stretch;

  @include media-breakpoint-up($breakpoint) {
    @each $value in $positions {
      .u-justify-content-#{$value}-#{map-get($grid-classes, $breakpoint)} {
        justify-content: $value;
      }

      .u-justify-self-#{$value}-#{map-get($grid-classes, $breakpoint)} {
        justify-self: $value;
      }
    }
  }
}

@mixin create-align-utils( $breakpoint ) {
  $positions: 
    center, 
    start, 
    end, 
    flex-start, 
    flex-end, 
    baseline, 
    stretch;

  @include media-breakpoint-up($breakpoint) {
    @each $value in $positions {
      .u-align-content-#{$value}-#{map-get($grid-classes, $breakpoint)} {
        align-content: $value;
      }

      .u-align-self-#{$value}-#{map-get($grid-classes, $breakpoint)} {
        align-self: $value;
      }
    }
  }
}

@each $breakpoint in xsmall, small, medium, large {
  @include create-justify-utils( $breakpoint );
  @include create-align-utils( $breakpoint );
}

.u-sticky {
  @include media-breakpoint-up(medium) {
    position: sticky;
    top: $grid-gap * 3;
  }
}

.u-scroll-lock {
  // when modal active
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  // Other browsers 
  overscroll-behavior: none;
}

// 
// Project specific 
//

.u-heading-line {
  border-bottom: 1px solid $border-color;
  margin-left: $page-margin-negative;
  padding-bottom: $grid-gap;
  padding-left: $page-margin-positive;
  margin-bottom: $grid-gap * 1.5;
}
