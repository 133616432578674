:root {
  // Set AnimXYZ defaults
  --xyz-stagger-default: 0.3s;
  --xyz-duration-default: 1s;
  --xyz-translate-default: 12.5%;
}

[data-xyz] {
  opacity: 0;
}

.xyz-out {
  animation-duration: 0s;
}

@media print {
  [xyz],
  [data-xyz],
  .xyz-out,
  .xyz-in,
  .xyz-nested {
    animation: none !important;
    transform: none !important;
    opacity: 1 !important;
  }

  * {
    color: $color-base !important;
    
    &,
    &::before,
    &::after {
      background: none !important;
    }
  }
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  color: $color-tint-1;
  font-family: $font-family;
  font-size: $font-size-base;
  line-height: 1.45;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-breakpoint-up(medium) {
    background: url('/src/assets/bg-pattern.png') 100% 0 repeat-y;
  }
}

img {
  height: auto;
  max-width: 100%;
  width: auto;
}

ul,
ol,
p,
address {
  margin: 0 0 1em;
}

a {
  text-decoration: none;
  color: $color-highlight-1;
  transition: color 0.15s ease;

  &:hover,
  &:focus {
    color: $color-highlight-2;
  }
}

address {
  font-style: normal;
}

input {
  font-family: inherit;
}

input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}

::selection {
  background: $color-highlight-1;
}

::marker {
  color: $color-highlight-1;
  font-size: 1em;
}

#ie-prompt {
  background: $color-base;
  color: #fff;
  text-align: center;
  padding: 30px 15px;
  font-weight: bold;
}