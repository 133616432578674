.Office {
  font-style: normal;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;

  &-title {
    @include h4;
    padding-bottom: 0.75em;
    margin-bottom: 1em;
    border-bottom: 1px solid $border-color;
  }

  &-flag {
    margin-bottom: $grid-gap;
    align-self: flex-start;
  }

  &-address {
    margin-bottom: auto;
  }

  &-tel,
  &-directions {
    margin: 1em 0 0;
  }
}
