.Menu {
  transition:
    opacity 0.3s ease,
    transform 0.3s ease,
    visibility 0.3s ease;

  @include media-breakpoint-down(xlarge) {
    padding: 0 10px;
  }

  @include media-breakpoint-up(xlarge) {
    background: #fff;
    border: 1px solid $border-color;
    border-top: none;
    left: 0;
    line-height: 1.2;
    opacity: 0;
    padding: 10px;
    position: absolute;
    top: 100%;
    transform: translateY(30px);
    visibility: hidden;
    width: 180px;
    z-index: 1001;
  }

  .Header-navItem:hover & {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  &-title {
    border-bottom: 1px solid $color-tint-3;
    margin: 0;
  }

  &-titleLink,
  &-link {
    color: $color-highlight-1;
    display: block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.1;
    padding: 1.2em 0;
    text-transform: uppercase;

    @include media-breakpoint-up(xlarge) {
      padding: 1em 0;
    }
  }

  &-items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-item {

    & + & {
      border-top: 1px solid $color-tint-4;
    }
  }

  &-link {
    color: $color-tint-1;
  }
}
