%heading {
  color: $color-base;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5em;
  margin-top: 0;
}

@mixin h1 {
  @extend %heading;
  @include fluid-type( 36px, 50px );
}

@mixin h2 {
  @extend %heading;
  @include fluid-type( 24px, 36px );
}

@mixin h3 {
  @extend %heading;
  @include fluid-type( 20px, 26px );
}

@mixin h4 {
  @extend %heading;
  @include fluid-type( 18px, 22px );
  font-weight: normal;
}

@mixin h5 {
  @extend %heading;
}

h1,
.H1 {
  @include h1;
}

h2,
.H2 {
  @include h2;
}

h3,
.H3 {
  @include h3;
}

h4,
.H4 {
  @include h4;
}

h5,
.H5 {
  @include h5;
}

%blockquote,
.Blockquote {
  font-weight: 500;
  padding-left: 50px;
  margin: 1.5em 0;
  border-left: 2px solid $color-highlight-2;
  color: $color-base;
  position: relative;

  @include fluid-type( 20px, 26px );

  &::before {
    @include fluid-type( 30px, 50px );
    content: '\201C';
    position: absolute;
    left: 15px;
    top: 0;
    line-height: 1;
  }

  &::after {
    content: '\201D';
  }
}

%Lead,
.Lead {
  @include fluid-type( 20px, 22px );
  color: $color-base;
}

%Meta,
.Meta {
  color: #9c9ea9;
  display: block;
  font-size: $font-size-small;
  font-weight: 500;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: $grid-gap * 1.5;
}

.Rule {
  border: 0;
  height: 1px;
  background: $border-color;
  margin: $grid-gap * 2 0;

  &--pullLeft {
    margin-left: $page-margin-negative;
  }

  &--pullRight {
    margin-left: $page-margin-negative;
  }

  &--primary {
    @include fluid-prop( margin-top margin-bottom, $grid-gap * 1.5, $grid-gap * 3 );
  }
}