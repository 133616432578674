.ScrollCue {
  $comp: &;

  align-items: center;
  align-self: start;
  display: flex;
  font-size: 12px;
  justify-self: end;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;

  @include media-breakpoint-down(medium) {
    display: none;
  }

  &-inner {
    align-items: center;
    align-self: start;
    display: flex;
  }

  &-icon {
    align-items: center;
    background: $color-base;
    color: #fff;
    display: flex;
    font-size: 18px;
    height: 40px;
    justify-content: center;
    margin-left: 15px;
    width: 40px;
  }

  // Modifiers 

  &--primary {
    margin-bottom: $grid-gap;
    align-self: auto;

    &::before {
      content: '';
      display: block;
      flex: 1;
      height: 1px;
      background: $border-color-inverse;
      margin-right: $grid-gap;
      margin-left: $page-margin-negative;
    }

    #{$comp}-icon {
      background: $color-highlight-1;
    }
  }

  &--whiteOut {
    color: #fff;
  }
}
