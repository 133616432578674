.ArticleBody {
  $comp: &;

  @include media-breakpoint-down(medium) {
    margin-bottom: $grid-gap * 1.5;
  }

  &-footer {
    display: grid;
    gap: $grid-gap;
    border-top: 1px solid $border-color;

    @include fluid-prop( margin-top, $grid-gap, $grid-gap * 2 );
    @include fluid-prop( padding-top, $grid-gap * 1.5, $grid-gap * 3 );

    * {
      justify-self: start;
    }

    @include media-breakpoint-up(small) {
      grid-template-columns: auto auto;
      align-items: center;

      *:last-child:not(:first-child) {
        justify-self: end;
      }
    }
  }
}
