.ArticlesHeader {
  border-bottom: 1px solid $border-color;
  margin-left: $page-margin-negative;
  margin-bottom: $grid-gap * 1.5;
  padding-bottom: $grid-gap;
  padding-left: $page-margin-positive;

  &-heading {
    margin-bottom: 0.25em;
  }

  &-lead {
    margin-bottom: 0;
  }
}
