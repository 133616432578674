.Signpost {
  color: $color-tint-2;
  
  @media screen {
    min-height: 280px;
    background: $color-base;
    padding: $grid-gap * 1.5;
    display: flex;
    flex-direction: column;
  }

  &-title {
    color: #fff;
  }

  &-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    font-size: 18px;

    svg {
      margin-left: 10px;
    }

    @media print {
      display: none;
    }
  }
}
