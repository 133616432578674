.Accordion {
  list-style: none;
  padding: 0;
  margin: $grid-gap 0;

  &:first-child {
    margin-top: 0;
  }

  &-item {
    border-top: 1px solid $border-color;
  }

  &-toggle {
    @include h4;
    @include fluid-prop(padding-top padding-bottom, 15px, 30px);

    padding-left: 0;
    padding-right: 0;
    margin: 0;
    display: block;
    width: 100%;
    text-align: left;
    background: svg-load('../assets/icons/plus.svg', fill=$color-highlight-1) calc(100% - 5px) 50% / 16px auto no-repeat;

    &[aria-expanded='true'] {
      background-image: svg-load('../assets/icons/minus.svg', fill=$color-highlight-1);
    }
  }

  &-panel {
    transition-property: max-height, visibility, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    max-height: var(--max-height, 100%);
    overflow: hidden;
    opacity: 1;

    &[hidden] {
      max-height: 0;
      visibility: hidden;
      display: block;
      opacity: 0;
      transition-timing-function: ease-out;
    }
  }

  &-panelInner {
    padding-bottom: 20px;
  }
}

@keyframes fadeIn {
  0% {
    max-height: 0;
    visibility: hidden;
  }

  100% {
    max-height: 200px;
    visibility: visible;
  }
}