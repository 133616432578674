.TestimonialCarousel {
  &::before {
    background: url('/src/assets/bg-pattern.png');
    content: '';
    display: block;
    position: absolute;
    left: $page-margin-negative;
    right: 100%;
    top: 0;
    height: 100%;
    z-index: -1;
  }

  &::after {
    background: url('/src/assets/cci-watermark-white.svg') 100% 0 / contain no-repeat;
    content: '';
    display: block;
    position: absolute;
    right: $page-margin-negative;
    top: 0;
    height: 330px;
    width: 100%;
    z-index: -1;
  }

  &-meta {
    @extend %Meta;
    color: $color-highlight-2;
  }

  &-track {
    overflow: hidden;
    position: relative;
    margin: 0 0 $grid-gap * 2;
  }
  
  &-slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    margin: 0 0 $grid-gap * 1.5;

    @include media-breakpoint-up(small) {
      margin: 0;
    }
  }
  
  &-slides--dragging {
    user-select: none;
  }
  
  &-slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  &-slide a {
    user-select: none;
  }
  
  &-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(small) {
      position: absolute;
      right: 0;
      bottom: 21px;
      justify-content: flex-end;
    }
  }

  &-arrows {
    -webkit-touch-callout: none;
    user-select: none;
  }
  
  &-arrow {
    color: #fff;
    font-size: 20px;
    background-color: $color-highlight-2;
    width: 42px;
    height: 42px;
  }

  &-bullets {
    -webkit-touch-callout: none;
    user-select: none;
    display: flex;
    justify-content: center;
    margin-right: $grid-gap;
  }

  &-bullet {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 24px;
    height: 24px;
    border: 8px solid transparent;
    background: $color-tint-2 content-box;
    transition: background 0.3s ease;

    &--active {
      background-color: $color-highlight-2;
    }
  }
}
