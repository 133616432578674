.FacetDateFilter {
  border: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-tint-4;
  margin-bottom: 10px;

  &-legend {
    display: block;
    color: $color-tint-2;
    font-size: $font-size-small;
    flex: 0 0 calc(20% - 5px);
  }

  &-select {
    background: transparent;
    border: 0;
    appearance: none;
    color: inherit;
    font-family: inherit;
    font-size: 16px;
    height: 40px;
    padding: 0 5px;
    background: svg-load('../assets/icons/chevron-down.svg', fill=$color-highlight-1) calc(100% - 5px) 50% / 10px auto no-repeat;
    flex: 0 0 calc(40% - 5px);
  }
}
