.Credentials {
  $comp: &;

  &-header {
    border-bottom: 1px solid $border-color-inverse;
    grid-column-end: 5;
    grid-column-start: 1;
    margin-left: calc(-1 * (#{percentage(1/10)} + #{$grid-gap}));
    padding-bottom: $grid-gap;
    padding-left: calc(1 * (#{percentage(1/10)} + #{$grid-gap}));
    margin-bottom: $grid-gap * 2;
  }

  &-lead {
    @include media-breakpoint-up(medium) {
      max-width: 75%;
    }
  }

  &-stats {
    @include media-breakpoint-up(medium) {
      margin: 0 (-$grid-gap);
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
    }
  }

  &-stat {

    @include media-breakpoint-up(medium) {
      padding: 0 1vw;

      & + & {
        border-left: 1px solid $border-color-inverse;
      }
    }

    @include media-breakpoint-down(medium) {
      margin-bottom: $grid-gap;
      width: 100%;

      & + & {
        border-top: 1px solid $border-color-inverse;
        padding: $grid-gap 0 0;
      }

      border-bottom: none;
      width: 90%;
    }
  }
}

.Credentials.Credentials--3 {
  @include media-breakpoint-up(medium) {
    .Credentials-stats {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      .Credentials-stat {
        flex: 1;
      }
    }
  }
  .Stat-name {
    max-width: 24ch;
  }
}

.Credentials.Credentials--4 {
  .Credentials-stats {
    display: flex;
    flex-wrap: wrap;
    .Credentials-stat {
      @include media-breakpoint-down(large) {
        border-left: none;
        border-bottom: 1px solid $border-color-inverse;
        border-left: none;
        margin-right: 40px;
        padding-bottom: 20px;
        padding-top: 20px;
        width: calc(50% - 40px);
        .Stat-name {
          max-width: 75%;
        }
      }
      @include media-breakpoint-up(large) {
        .Stat-name {
          max-width: 19ch;
        }
      }
      @include media-breakpoint-down(medium) {
        margin-bottom: $grid-gap;
        width: 100%;
        & + & {
          border-top: 1px solid $border-color-inverse;
          padding: $grid-gap 0 0;
        }
        border-bottom: none;
        width: 90%;
      }
    }
  }
}

.Credentials.Credentials--4-even {
  .Credentials-stats {
    display: flex;
    flex-wrap: wrap;
    .Credentials-stat {
      @include media-breakpoint-down(large) {
        border-left: none;
        border-bottom: 1px solid $border-color-inverse;
        border-left: none;
        margin-right: 40px;
        padding-bottom: 20px;
        padding-top: 20px;
        width: calc(50% - 40px);
        .Stat-name {
          max-width: 75%;
        }
      }
      @include media-breakpoint-up(large) {
        flex-basis: 25%;
        flex-grow: 0;
      }
      @include media-breakpoint-down(medium) {
        margin-bottom: $grid-gap;
        width: 100%;
        & + & {
          border-top: 1px solid $border-color-inverse;
          padding: $grid-gap 0 0;
        }
  
        border-bottom: none;
        width: 90%;
      }
    }
  }
}