.Carousel {
  $comp: &;

  margin: 0 percentage(1/12);

  &-inner {
    position: relative;
  }

  &-track {
    overflow: hidden;
  }
  
  &-slides {
    position: relative;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    margin: 0;
  }
  
  &-slides--dragging {
    user-select: none;
  }
  
  &-slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  &-slide a {
    user-select: none;
  }
  
  &-arrows {
    -webkit-touch-callout: none;
    user-select: none;
  }
  
  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $color-highlight-2;
    
    @include fluid-prop(font-size, 24px, 48px );

    &--prev {
      left: -(percentage(1/10));
    }

    &--next {
      right: -(percentage(1/10));
    }
  }

  &-nav {
    -webkit-touch-callout: none;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid $border-color;
    margin: 0 (-(percentage(1/10)));

    @include fluid-prop(padding-bottom, $grid-gap * 1.5, $grid-gap * 2.5 );
    @include fluid-prop(margin-bottom, $grid-gap, $grid-gap * 2 );
  }

  &-navItem {
    height: 40px;
    border: 1px solid $border-color;
    color: $color-base;
    transition: background 0.3s ease;
    padding: 0 15px;
    margin: 0 5px 10px;

    @include fluid-prop(font-size, 16px, 18px );

    &--active {
      border-color: rgba($color-highlight-2, 0.3);
      color: $color-highlight-2;
    }

    @include media-breakpoint-up(medium) {
      margin: 0 10px;
    }
  }

  // Modifiers

  &--inverse {
    #{$comp}-nav {
      border-color: $border-color-inverse;
    }

    #{$comp}-navItem:not(#{$comp}-navItem--active) {
      border-color: $border-color-inverse;
      color: #fff;
    }
  }
}
