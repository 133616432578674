.Header {
  background: #fff;
  display: flex;
  height: $header-height-small;
  padding: 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-bottom: 1px solid $border-color;

  @include fluid-prop(padding-left padding-right, 20px, 30px);

  @include media-breakpoint-up(xlarge) {
    height: $header-height-large;
    padding-right: 0;
  }

  @media print {
    position: relative;
  }

  &-brand {
    align-self: center;
    width: 60px;

    @include media-breakpoint-up(xlarge) {
      width: auto;
    }
  }

  &-mobileControls {
    align-items: center;
    display: flex;
    margin-left: auto;

    @include media-breakpoint-up(xlarge) {
      display: none;
    }

    @media print {
      display: none;
    }
  }

  &-mobileControl {
    color: $color-base;
    font-size: 20px;
    margin-left: $grid-gap;
  }

  &-nav {
    @include media-breakpoint-down(xlarge) {
      background: #fff;
      bottom: 0;
      left: 0;
      opacity: 0;
      overflow: auto;
      padding: 0 $grid-gap $grid-gap;
      position: fixed;
      right: 0;
      top: 50px;
      transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
      visibility: hidden;

      &[hidden] {
        display: block;
      }

      &.is-expanded {
        opacity: 1;
        visibility: visible;
      }
    }

    @include media-breakpoint-up(xlarge) {
      align-items: center;
      display: flex;
      margin: 0 0 0 auto;
      padding: 0;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        display: block;
        z-index: -1;
      }

      @include fluid-prop(padding-left padding-right, 20px, 30px);
    }
  }

  &-navItems {
    @extend %list-unstyled;

    @include media-breakpoint-up(xlarge) {
      align-items: center;
      display: flex;
    }
  }

  &-navItem {
    position: relative;

    @include media-breakpoint-down(xlarge) {
      border-bottom: 1px solid $color-tint-3;
    }

    @include media-breakpoint-up(xlarge) {
      & + & {
        margin-left: 10px;
      }
    }
  }

  &-navLink,
  &-navControlLink {
    color: #3b4363;
    font-size: $font-size-small;
    font-weight: 500;
    letter-spacing: 0.1em;
    padding: 1.5em 10px;
    text-decoration: none;
    text-transform: uppercase;

    svg {
      color: $color-highlight-1;
      margin-left: 5px;
    }

    @include media-breakpoint-down(xlarge) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-up(xlarge) {
      font-size: 12px;
      padding: 10px;
    }
  }

  &-navControl {
    @include media-breakpoint-down(xlarge) {
      svg {
        order: 1;
      }
    }

    @include media-breakpoint-up(xlarge) {
      border-left: 1px solid $border-color;
      margin-left: 20px;
      padding-left: 20px;
    }

    &--search {
      @include media-breakpoint-down(xlarge) {
        display: none;
      }
    }
  }

  &-navControlLink {
    width: 100%;

    svg {
      font-size: 18px;
    }

    @include media-breakpoint-up(xlarge) {
      svg {
        margin-right: 10px;
      }
    }
  }
}
