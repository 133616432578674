.Facets {
  position: sticky;
  top: $grid-gap;

  @include media-breakpoint-up(large) {
    padding: 0 percentage(( $grid-gap * 3 ) / ((( $grid-container-width / 12 ) * 4 ) - $grid-gap ));
  }
}

.FacetsHeading {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  padding-top: $grid-gap;

  svg {
    color: $color-tint-2;
    font-size: 18px;
  }
}