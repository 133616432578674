@import './mixins/breakpoints';
@import './mixins/fluid';

@mixin container() {
  margin: 0 auto;
  max-width: $grid-container-width;
  padding: 0 $grid-gap;
}

@mixin inversed-text() {
  h2,
  h3,
  h4 {
    color: #fff;
  }

  &,
  p,
  ul,
  ol {
    color: rgba(#fff, 0.7);
  }
}