.ArticleMeta {
  display: flex;
  align-items: center;
  margin-bottom: $grid-gap / 2;

  &-date,
  &-category {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
    margin-right: 10px;
  }

  &-date {
    color: $color-tint-2;
  }

  &-category {
    background: var(--category-color, $color-highlight-1);
    color: #fff;
    padding: 0.25em 0.4em 0.2em;
    letter-spacing: 0.15em;
  }

  // Modifiers

  &--inverse &-date {
    color: #fff;
  }
}
