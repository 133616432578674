.FrontCover {
  min-height: calc(100vh - #{$header-height-small});
  background: $color-base;
  color: #fff;
  display: flex;
  align-items: center;
  border-right: 5px solid $color-highlight-2;
  overflow: hidden;
  position: relative;
  
  @include media-breakpoint-up(xlarge) {
    min-height: calc(100vh - #{$header-height-large});
    padding-right: 0;
  }

  @media print {
    min-height: 0;
    border-right: 0;
  }

  &-inner {
    @include container;
    @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );
    position: relative;
    z-index: 1;
  }

  &-heading {
    @include fluid-type( 50px, 110px );

    color: #fff;
    position: relative;
    margin-bottom: $grid-gap;
    word-wrap: nowrap;

    span {
      white-space: pre;
    }
    
    &::before {
      @include fluid-prop( width, 320px, 800px );
      @include fluid-prop( height, 198px, 493px );

      background: url('/src/assets/cci-watermark-white.svg') 0 50% / auto 100% no-repeat;
      content: '';
      display: block;
      left: $page-margin-negative;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      opacity: 0.5;
    }
  }

  &-body {
    @include media-breakpoint-up(medium) {
      width: 50%;
    }
  }

  &-lead {
    @include fluid-type( 18px, 20px );
    margin-bottom: 2em;
  }

  &-buttonGroup {
    display: flex;
    flex-wrap: wrap;
    gap: $grid-gap * 2;

    @media print {
      display: none;
    }
  }

  &-videoContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
    opacity: 0.33;

    @media print {
      display: none;
    }
  }

  &-video {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media print {
      display: none;
    }
  }
}
