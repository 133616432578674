.Person {
  display: flex;
  margin-bottom: $grid-gap * 1.5;

  &-imgContainer {
    flex: 0 1 75px;
    margin-right: $grid-gap;
  }

  &-meta {
    color: $color-tint-2;
    display: block;
    font-size: $font-size-small;
  }

  &-name {
    font-size: 22px;
    margin-bottom: 0;
    color: $color-base;
  }

  &-jobtitle {
    margin-bottom: 0;
  }
}
