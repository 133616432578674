.Section {
  $comp: &;

  @media screen {
    @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );
  }

  @media print {
    margin-bottom: $grid-gap * 2;
  }

  &-inner {
    @include container;
  }

  &-body {
    position: relative;
  }

  &-img {
    height: 100%;
    left: 0;
    object-fit: contain;
    object-position: 100% 50%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @media print {
      display: none;
    }
  }

  // Modifiers

  &--primary,
  &--secondary {
    @media screen {
      border-left: 5px solid $color-highlight-2;
      border-image: $gradient-2 0 100%;
      padding-right: 5px;
    }
    
    @include fluid-prop( margin-top margin-bottom, $grid-gap * 1.5, $grid-gap * 3 );
  }

  &--primary {
    background: $color-base;
    @include inversed-text;
  }

  &--secondary {
    background: $color-tint-4;
  }

  &--panel {
    overflow: hidden;
    @include inversed-text;

    #{$comp}-inner {
      @media screen {
        position: relative;

        &::after {
          background: $color-base;
          border: 0;
          border-left: 5px solid;
          border-image: $gradient-1 0 100%;
          content: '';
          display: block;
          height: 100%;
          left: $grid-gap;
          position: absolute;
          right: $page-margin-negative;
          top: 0;
          z-index: -1;
        }
      }

      @include media-breakpoint-up(medium) {
        background-position: 0 center;
      }
    }

    #{$comp}-body {
      @media screen {
        padding-left: $grid-gap * 1.5;
        @include fluid-prop( padding-top padding-bottom, $grid-gap * 2, $grid-gap * 4 );
      }

      @include media-breakpoint-up(medium) {
        padding-left: percentage(1/12);
        padding-right: percentage(1/12);
      }
    }
  }

  &--patterned {
    background: url('/src/assets/bg-pattern.png') 0 0 repeat-y;
  }

  &--hasWatermark {
    #{$comp}-inner::after {
      background: $color-base url('/src/assets/world-watermark.png') 0 0 / contain no-repeat;
    }
  }

  &--indented {
    #{$comp}-inner {
      position: relative;

      &::after {
        background: url('/src/assets/bg-pattern.png') 0 0 repeat;
        content: '';
        display: block;
        height: 100%;
        width: $page-margin-positive;
        position: absolute;
        left: $page-margin-negative;
        top: 0;
        z-index: -1;
      }
    }

    #{$comp}-body {
      @include media-breakpoint-up(medium) {
        padding: 0 percentage(2/12);
      }
    }
  }

  &--separator {
    @include fluid-prop( margin-top, $grid-gap * 1.5, $grid-gap * 3 );

    #{$comp}-inner {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 0;
        left: $page-margin-negative;
        right: $grid-gap;
        border-bottom: 1px solid $border-color;

        @include fluid-prop( top, -$grid-gap * 3, -$grid-gap * 1.5 );
      }
    }
  }

  &--hasImage {
    #{$comp}-inner::after {
      background-image: none;
    }

    #{$comp}-img {
      left: auto;
      mask-image: url('/src/assets/cci-mask.svg');
      mask-position: 100% 50%;
      mask-repeat: no-repeat;
      mask-size: contain;
      opacity: 0.3;
      right: $page-margin-negative;
    }
  }

  &--nospacer {
    padding-top: 0;
    padding-bottom: 0;
  }
}
