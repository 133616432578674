.ArticleFragment {
  $comp: &;

  position: relative;

  &-link {
    color: inherit;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: $color-highlight-1;
    }

    &::before {
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &-title {
    &:last-child {
      margin-bottom: 0;
    }

    &--h3 {
      @include h3;
    }

    &--h4 {
      @include h4;
    }
  }

  &-desc {
    margin-bottom: 0;
  }
  

  // Modifiers

  &--hero {
    color: #fff;
    height: 392px;
    display: flex;
    align-items: flex-end;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba($color-base, 0) 45.4%, $color-base 100%);
      z-index: -1;
    }

    #{$comp}-meta {
      padding-bottom: $grid-gap;
      margin-left: -$grid-gap;
      padding-left: $grid-gap;
      border-bottom: 1px solid $border-color-inverse;
      color: #fff;

      @include media-breakpoint-up(medium) {
        @include fluid-prop(padding-left, $grid-gap, 50px);
        @include fluid-prop(margin-left, -50px, -$grid-gap);
      }
    }

    #{$comp}-title {
      @include h3;
    }

    #{$comp}-imgContainer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background: $color-tint-4;
    }

    #{$comp}-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: none;
      object-fit: cover;
    }

    #{$comp}-body {
      padding: 0 $grid-gap;
      @include fluid-prop(padding-top padding-bottom, $grid-gap, $grid-gap * 2);

      @include media-breakpoint-up(medium) {
        width: percentage(5/8);
        @include fluid-prop(padding-left, $grid-gap, 50px);
      }
    }

    #{$comp}-date,
    #{$comp}-title {
      color: inherit;
    }
  }

  &--vertical {
    #{$comp}-img {
      margin-bottom: $grid-gap;
    }
  }

  &--horizontal {
    display: flex;

    #{$comp}-imgContainer {
      flex: 0 0 calc(50% - #{$grid-gap});
      margin-right: $grid-gap;
      max-width: 196px;
    }
  }
}
