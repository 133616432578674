.SearchBox {
  background: $color-tint-4;
  padding: 30px $page-margin-positive;
  margin: 0 $page-margin-negative;
  
  @include fluid-prop(margin-bottom, 30px, 40px);

  @include media-breakpoint-up(large) {
    display: flex;
    margin-right: 0;
    padding-right: 30px;
  }

  &-input {
    @extend %FormControl;
    @extend %FormControl--lg;
    flex: 1;
    @include fluid-type(16px, 20px);
  }

  &-btn {
    margin-top: 15px;

    @include media-breakpoint-up(large) {
      margin-left: 20px;
      margin-top: 0;
      width: auto;
    }
  }
}