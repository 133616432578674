.ImageMask {
  mask-image: url('/src/assets/cci-mask.svg');
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-size: contain;

  @media print {
    display: none;
  }
}
