@import-normalize;

@import '../../node_modules/@animxyz/core/dist/animxyz.min.css';

@import './functions';
@import './variables';
@import './mixins';
@import './placeholders';
@import './globals';
@import './typography';
@import './utilities';

@import './components/Slider.scss';
@import './components/Carousel.scss';
@import './components/Header.scss';
@import './components/SearchBar.scss';
@import './components/Footer.scss';
@import './components/ScrollCue.scss';
@import './components/FrontCover.scss';
@import './components/UI/Grid.scss';
@import './components/UI/Button.scss';
@import './components/UI/LoadMore.scss';
@import './components/UI/Icon.scss';
@import './components/UI/Hamburger.scss';
@import './components/UI/Menu.scss';
@import './components/UI/Image.scss';
@import './components/UI/Accordion.scss';
@import './components/ImageMask.scss';
@import './components/LinkBlock.scss';
@import './components/ImageBlock.scss';
@import './components/SplitBlock.scss';
@import './components/ContentBlock.scss';
@import './components/Card.scss';
@import './components/Bio.scss';
@import './components/Testimonial.scss';
@import './components/Cover.scss';
@import './components/Section.scss';
@import './components/SectionHeader.scss';
@import './components/UI/Facets.scss';
@import './components/UI/Facet.scss';
@import './components/UI/FacetFilter.scss';
@import './components/UI/FacetDateFilter.scss';
@import './components/ArticleList.scss';
@import './components/ArticlesHeader.scss';
@import './components/ArticleMeta.scss';
@import './components/ArticleFragment.scss';
@import './components/ArticleHeader.scss';
@import './components/ArticleBody.scss';
@import './components/ArticleAside.scss';
@import './components/Person.scss';
@import './components/Credentials.scss';
@import './components/Share.scss';
@import './components/Signpost.scss';
@import './components/UI/DescriptionList.scss';
@import './components/Office.scss';
@import './components/UI/Form.scss';
@import './components/UI/Search.scss';
@import './components/SearchResults.scss';
@import './components/SearchResult.scss';
@import './components/Error404.scss';
@import './components/History.scss';
@import './components/LocationsMap.scss';
@import './components/CtaBlock.scss';
@import './components/CtaStripBlock.scss';
@import './components/VideoPlayer.scss';
@import './components/ContentCarousel.scss';
@import './components/Stat.scss';
@import './components/AnchorNav.scss';
@import './components/UI/TabbedContent.scss';
@import './components/Event.scss';
@import './components/TestimonialCarousel.scss';
@import './components/UI/Modal.scss';
@import './components/ModalCarousel.scss';
@import './components/GalleryCarousel.scss';
@import './components/Lister/Lister.scss';
@import './components/CourseCard.scss';
@import './components/UI/Pagination.scss';
@import './components/UI/Spinner.scss';
@import './components/UmbracoForms.scss';
