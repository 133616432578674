.ArticleHeader {
  &-inner {
    @include container;
    @include fluid-prop( padding-top, $grid-gap * 1.5, $grid-gap * 3 );
    @include fluid-prop( padding-bottom, $grid-gap * 1.5, $grid-gap * 2 );

    position: relative;

    @include media-breakpoint-up(medium) {
      display: grid;
      row-gap: $grid-gap / 2;
      column-gap: $grid-gap;
      grid-template-columns: 9.5fr 2.5fr;
      grid-template-rows: auto auto;
      grid-template-areas:
        'meta meta'
        'title aside';
    }

    @include media-breakpoint-up(large) {
      grid-template-columns: 1fr 8fr 3fr;
      grid-template-areas:
        '. meta meta'
        '. title aside';

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: $page-margin-negative;
        right: calc(100% - #{$grid-gap});
        bottom: 0;
        background: url('/src/assets/bg-pattern.png') 0 0 repeat;
      }
    }
  }

  &-meta {
    grid-area: meta;
  }

  &-title {
    @include h2;
    margin: 0;
    grid-area: title;
  }

  &-aside {
    border-top: 1px solid $border-color;
    padding-top: $grid-gap;
    margin-top: $grid-gap;
    grid-area: aside;
    
    @include media-breakpoint-up(medium) {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
      border-left: 1px solid $border-color;
      padding-left: $grid-gap;
      justify-self: end;
      align-self: start;
    }
  }
}
