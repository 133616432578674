.CtaBlock {
  padding-left: $grid-gap * 1.5;
  padding-right: $grid-gap * 1.5;
  border-color: $color-highlight-2;
  border-left-style: solid;
  border-left-width: 5px;
  position: relative;

  @include fluid-prop( padding-top padding-bottom, $grid-gap * 2, $grid-gap * 4 );
  @include inversed-text;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(90deg, $color-base 40.36%, rgba($color-base, 0) 100%);
  }

  @include media-breakpoint-up(medium) {
    padding-left: percentage(1/12);
    padding-right: percentage(1/12);
  }

  @media print {
    display: none;
  }

  &-inner {
    border-left: 1px solid $border-color-inverse;
    text-align: center;
    padding-left: $grid-gap;
    padding-right: $grid-gap;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(medium) {
      padding: 0 percentage(2/10);
    }
  }

  &-bgImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  &-heading {
    margin-bottom: $grid-gap;
  }

  &-btn {
    margin-top: $grid-gap * 1.5;
  }

  .Section:nth-child(even) & {
    border-color: $color-highlight-1;
  }
}