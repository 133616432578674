.Button {
  $comp: &;

  align-items: center;
  border: 2px solid $color-highlight-1;
  color: $color-highlight-1;
  display: inline-flex;
  font-size: $font-size-small;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transition: background 0.15s ease, color 0.15s ease;
  padding: 0 20px;
  height: 50px;

  svg {
    font-size: 18px;
  }

  &:hover,
  &:focus {
    background: $color-highlight-1;
    color: #fff;
  }

  &[hidden] {
    display: none;
  }

  @media print {
    display: none !important;
  }

  &-label {
    &:not(:last-child) {
      margin-right: 20px;
    }

    &:first-child:last-child {
      margin: 0 auto;
    }
  }

  // Modifiers

  &--block {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &--hide-label-sm &-label {
    @include media-breakpoint-down(medium) {
      display: none;
    }
  }

  &--primary {
    background-color: $color-highlight-1;
    color: #fff;

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: $color-highlight-1;
      color: $color-highlight-1;
    }
  }

  &--secondary {
    border-color: transparent;
    height: auto;
    padding: 0;

    &:hover,
    &:focus {
      background: transparent;
      color: $color-highlight-2;
    }
  }

  &--md {
    height: 40px;
    padding: 0 16px;
    font-size: 12px;

    #{$comp}-label {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &--sm {
    height: 30px;
    padding: 0 10px;
    font-size: 12px;

    #{$comp}-label {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &--no-label {
    padding: 0 10px;
  }

  &--no-label#{$comp}--sm {
    padding: 0 5px;
  }
}

.ButtonGroup {
  display: flex;
  flex-wrap: wrap;

  .Button {
    margin: 0 $grid-gap $grid-gap 0;
  }

  @include media-breakpoint-down(medium) {
    flex-direction: column;

    .Button {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

%ButtonLink,
.ButtonLink {
  align-items: center;
  color: $color-highlight-1;
  display: inline-flex;
  font-size: $font-size-small;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transition: background 0.15s ease, color 0.15s ease;
  white-space: nowrap;

  &:hover,
  &:focus {
    background: transparent;
    color: $color-highlight-2;
  }

  svg {
    margin-left: 4px;
  }
}