.FormRow {
  margin-bottom: $grid-gap * 1.5;
}

.FormLabel {
  font-weight: 500;
  color: $color-base;
  margin-bottom: 0.5em;
  display: block;

  @include media-breakpoint-up(medium) {
    font-size: 18px;
  }
}

%FormControl,
.FormControl {
  display: block;
  width: 100%;
  border: 1px solid $border-color;
  height: 44px;
  font-size: 16px;
  color: $color-base;
  padding: 10px;
  font-family: inherit;
  transition: border-color 0.3s ease;
  background: #fff;
  appearance: none;

  &:focus {
    border-color: $color-highlight-1;
    outline: 0;
  }

  &::placeholder {
    color: $color-base;
    opacity: 0.7;
  }

  &[aria-invalid='true'] {
    border-color: $color-error;
  }

  // Modifiers

  &--sm {
    height: 30px;
  }

  &--lg {
    height: 50px;
  }
}

textarea.FormControl {
  height: auto;
  resize: vertical;
}

.FormMessage {
  color: $color-error;
  font-size: $font-size-small;
  margin-top: 0.5em;
}

.FormSubmit {
  display: flex;
  justify-content: flex-end;
}
