.SearchBar {
  $comp: &;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 1000;
  background: $color-base;
  color: #fff;
  display: flex;
  height: 80px;
  padding: 0 $grid-gap;
  transform: translateY(30px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  
  @include media-breakpoint-up(large) {
    height: 140px;
  }

  &[hidden] {
    visibility: hidden;
    opacity: 0;
  }

  &:not([hidden]) {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
    visibility: visible;
  }

  &-inner {
    align-items: center;
    display: flex;
    flex: 1;
    margin: 0 auto;
    max-width: 960px;
    position: relative;

    @include media-breakpoint-up(large) {
      background: url('/src/assets/cci-watermark-white.svg') 0 50% / auto 100% no-repeat;
      padding: 0 50px;
      max-width: 1000px;
    }
  }

  &-field {
    appearance: none;
    background: transparent;
    border: 1px solid $color-tint-2;
    border-radius: 0;
    color: #fff;
    flex: 1;
    height: 50px;
    padding: 0 15px;
    transition: border-color 0.15s ease;

    @include fluid-type(18px, 20px);
    @include fluid-prop( margin-right, $grid-gap / 2, $grid-gap);

    &:focus {
      border-color: #fff;
      outline: none;
    }

    &::placeholder {
      color: #fff;
      opacity: 1;
    }
  }

  &-close {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    svg {
      display: block;
    }

    @include media-breakpoint-down(large) {
      display: none;
    }
  }

  // Modifiers

  &--inline {
    position: relative;
    top: auto;
  }
}
