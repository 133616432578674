.AnchorNav {
  $comp: &;
  
  @media print {
    display: none;
  }
  
  &-inner {
    list-style: none;
    padding: 0;
    overflow: hidden;

    @include media-breakpoint-up(small) {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: 1fr 1fr;
      position: relative;

      &::before {
        background: url('/src/assets/cci-watermark-white.svg') 50% 50% / auto 50%  no-repeat;
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        pointer-events: none;
      }
    }
  }

  &-item {
    position: relative;
    background: $color-base;
    border: solid $color-tint-3;
    border-width: 1px 0 0 1px;
    height: 170px;
    margin: -1px 0 0 -1px;

    @include fluid-prop(height, 100px, 170px );
  }

  &-img {
    opacity: 0.35;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: opacity 0.3s ease;
  }

  &-arrow {
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    height: 100%;
    background: $color-highlight-1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }

  &-icon {
    color: #fff;
    font-size: 24px;
  }

  &-link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    color: $color-highlight-1;
    transition: padding 0.3s ease;

    @include fluid-type( 20px, 26px );
    @include fluid-prop( padding-left padding-right, $grid-gap, $grid-gap * 3 );

    &:hover,
    &:focus {
      color: $color-highlight-1;
    }
  }

  &-item:nth-child(odd) {
    #{$comp}-arrow {
      left: 0;
      right: auto;
      transform: translateX(calc(-100% + 5px));

      #{$comp}-link:hover &,
      #{$comp}-link:focus & {
        transform: translateX(0);
      }
    }

    #{$comp}-link {
      justify-content: flex-start;

      &:hover,
      &:focus {
        @include fluid-prop( padding-left, $grid-gap + 45px, $grid-gap * 3 + 45px );
      }
    }
  }

  &-item:nth-child(even) {
    #{$comp}-arrow {
      right: 0;
      left: auto;
      transform: translateX(calc(100% - 5px));
    }

    #{$comp}-link {
      justify-content: flex-end;

      &:hover,
      &:focus {
        @include fluid-prop( padding-right, $grid-gap + 45px, $grid-gap * 3 + 45px );
      }
    }
  }

  &-item:nth-child(4n+4) &-arrow,
  &-item:nth-child(4n+4) + * &-arrow {
    background-color: $color-highlight-1;
  }

  &-item:nth-child(4n+2) &-arrow,
  &-item:nth-child(4n+2) + * &-arrow {
    background-color: $color-highlight-2;
  }

  &-link:hover,
  &-link:focus {
    #{$comp}-arrow {
      transform: translateX(0);
    }

    & + #{$comp}-img {
      opacity: 0.7;
    }
  }
}
