.Spinner {
  width: 48px;
  height: 48px;
  border: 5px solid $color-highlight-2;
  border-bottom-color: #fff;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  overflow: hidden;
  text-indent: 100%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}