.DescriptionList {
  @include media-breakpoint-up(medium) {
    columns: 2;
    column-gap: $grid-gap;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    @include media-breakpoint-up(medium) {
      margin-right: $grid-gap * 1.5;
      font-size: 18px;
    }
  }

  &-name,
  &-value {
    flex: 0 0 50%;
    min-width: 0;
    padding: $grid-gap / 2 0;
    border-bottom: 1px solid $border-color;
  }

  &-name {
    font-weight: 500;
    color: $color-base;
    padding-right: $grid-gap / 2;
  }

  &-value {
    margin: 0;
    padding-left: $grid-gap / 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
