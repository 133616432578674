.SplitBlock {
  $comp: &;

  @media screen {
    @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );
  }

  @media print {
    margin-bottom: $grid-gap * 2;
  }

  &-inner {
    @include container;

    gap: $grid-gap;
    grid-template-areas: 'image . body .';
    grid-template-columns: 5fr 1fr 5fr 1fr;

    @include media-breakpoint-up(medium) {
      display: grid;
    }

    @media print {
      display: grid;
      grid-template-areas: 'image body';
      grid-template-columns: 6fr 6fr;
    }
  }

  &-imgContainer {
    border: 0;
    border-right: 5px solid;
    border-image: $gradient-2 0 100%;
    grid-area: image;
    margin-bottom: $grid-gap;
    margin-left: $page-margin-negative;
    position: relative;
    z-index: 2;

    &::before {
      background: url('/src/assets/bg-pattern-white.png') 0 0 repeat-y;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      opacity: 0.3;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    @include media-breakpoint-up(medium) {
      margin-bottom: 0;
    }

    @media print {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  &:nth-child(odd) &-imgContainer {
    border-image: $gradient-1 0 100%;
  }

  &-img {
    display: block;

    @include media-breakpoint-up(medium) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media print {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-body {
    grid-area: body;

    p {
      margin-bottom: 0.5em;

      &:first-of-type:not(:last-of-type) {

        @extend %Lead;
        color: $color-base;
      }
    }

    @include media-breakpoint-up(medium) {
      @include fluid-prop( padding-top padding-bottom, $grid-gap * 2, $grid-gap * 4 );
    }
  }

  &-heading {
    margin-bottom: $grid-gap;

    &--h1 {
      border-bottom: 1px solid $border-color;
      padding-bottom: $grid-gap;
      @include h1;
    }

    &--h2 {
      @include h2;
    }

    &--h3 {
      @include h3;
    }
  }

  &-btn {
    margin-top: $grid-gap * 1.5;
  }
  
  &-expander {
    @include container;
    @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 4.5 );
    @include fluid-prop( padding-left padding-right, $grid-gap, $grid-gap * 3 );
    @include fluid-prop( margin-bottom, -$grid-gap * 3, -$grid-gap * 1.5 );
    @include inversed-text;

    position: relative;
    color: #fff;
    transition-property: max-height, visibility, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    opacity: 1;
    z-index: 1;
    margin-top: $grid-gap * 2;

    &[hidden] {
      max-height: 0;
      padding: 0;
      margin: 0;
      visibility: hidden;
      display: block;
      opacity: 0;
      transition-timing-function: ease-out;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: $page-margin-negative;
      left: $grid-gap;
      top: 0;
      bottom: 0;
      background: $color-base;
      z-index: -1;
      border-image: $gradient-1 0 100%;
      border-right: 5px + $grid-gap solid transparent;
    }

    @include media-breakpoint-up(medium) {
      @include fluid-prop( margin-top, -$grid-gap * 1.5, -$grid-gap * 0.5 );
    }
  }

  &-expanderActions {
    margin-top: $grid-gap * 3;
    display: flex;
    gap: $grid-gap * 1.5;

    @media print {
      display: none;
    }
  }

  // Modifiers

  &--reverse {
    #{$comp}-inner {
      background: #fff;
      position: relative;

      @include media-breakpoint-up(medium) {
        grid-template-areas: '. body . image';
        grid-template-columns: 1fr 5fr 1fr 5fr;

        &::before {
          background: url('/src/assets/bg-pattern.png') 0 0 repeat;
          content: '';
          display: block;
          left: $page-margin-negative;
          position: absolute;
          right: calc(#{percentage(7/12)} + #{$grid-gap});
          z-index: -1;

          @include fluid-prop( top bottom, -$grid-gap * 3, -$grid-gap * 1.5 );
        }
      }

      @media print {
        grid-template-areas: 'body image';
        grid-template-columns: 6fr 6fr;
      }
    }

    #{$comp}-imgContainer {
      border-left-style: solid;
      border-left-width: 5px;
      border-right: 0;
      margin-left: 0;
      margin-right: $page-margin-negative;

      &::before {
        content: none;
      }
    }
    
    #{$comp}-expander {
      &::before {
        left: $page-margin-negative;
        right: 0;
        border-left: 5px + $grid-gap solid transparent;
        border-right: 0;
      }
    }
  }

  &--hasMask {
    #{$comp}-inner {
      grid-template-areas: 'image body';
      grid-template-columns: 5fr 7fr;
    }

    #{$comp}-imgContainer {
      border-right: 0;
      margin-left: 0;
      mask-image: url('/src/assets/cci-mask.svg');
      mask-position: 50% 50%;
      mask-repeat: no-repeat;
      mask-size: contain;
      height: 0;
      overflow: hidden;
      padding-bottom: percentage(142/227);
      align-self: center;
    }

    #{$comp}-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    #{$comp}-heading {
      margin-right: $page-margin-negative;
      padding-right: $page-margin-positive;
    }
  }

  &--reverse#{$comp}--hasMask {
    #{$comp}-inner {
      grid-template-areas: 'body image';
      grid-template-columns: 7fr 5fr;
    }

    #{$comp}-imgContainer {
      border-left: 0;
      margin-right: 0;
    }

    #{$comp}-heading {
      margin-left: $page-margin-negative;
      margin-right: 0;
      padding-left: $page-margin-positive;
      padding-right: 0;
    }
  }

  &--bgImage,
  &--primary {
    #{$comp}-inner {
      position: relative;

      &::before,
      &::after {
        border-right: 5px solid $color-highlight-1;
        content: '';
        display: block;
        height: 100%;
        left: $page-margin-negative;
        position: absolute;
        right: $page-margin-negative;
        top: 0;
      }

      &::before {
        background: var(--splitblock-bg-image) 50% 50% / cover no-repeat;
        opacity: 0.2;
        z-index: -1;
      }

      &::after {
        z-index: -2;
      }

      @include media-breakpoint-up(medium) {

        &::before,
        &::after {
          right: $grid-gap;
        }
      }
    }

    #{$comp}-imgContainer {
      border: 0;

      &::before {
        content: none;
      }
    }

    #{$comp}-body {
      padding-right: $grid-gap * 1.5;

      @include media-breakpoint-up(medium) {
        padding-right: 0;

        @include fluid-prop( padding-top padding-bottom, $grid-gap * 2, $grid-gap * 4 );
      }
    }

    #{$comp}-heading {
      border-color: $border-color-inverse;
    }
  }

  &--bgImage {
    @include inversed-text;

    #{$comp}-inner {
      @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );

      &::after {
        background: $color-base;
      }
    }
  }

  &--primary {
    #{$comp}-inner {
      &::after {
        background: $color-tint-4;
      }

      @include media-breakpoint-down(medium) {
        @include fluid-prop( padding-top padding-bottom, $grid-gap, $grid-gap * 3 );
      }
    }
  }

  &--reverse#{$comp}--bgImage,
  &--reverse#{$comp}--primary {
    #{$comp}-inner {
      background: transparent;

      &::before,
      &::after {
        border-left: 5px solid $color-highlight-1;
        border-right: 0;
      }

      @include media-breakpoint-up(medium) {

        &::before,
        &::after {
          left: $grid-gap;
          right: $page-margin-negative;
        }
      }
    }

    #{$comp}-body {
      @include media-breakpoint-down(medium) {
        padding-left: 0;
      }
    }
  }
}
