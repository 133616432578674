.ContentCarousel {
  &-header {
    text-align: center;
    
    @include media-breakpoint-up(medium) {
      margin: 0 auto $grid-gap * 1.5;
      width: percentage(8/12);
    }
  }

  &-heading {
    display: block;
    @include h1;
  }

  &-carousel {
    &:not(:last-child) {
      margin-bottom: $grid-gap * 2;
    }
  }

  &-cta {
    text-align: center;
  }
}

.ContentSlide {
  @include media-breakpoint-up(medium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $grid-gap * 2;
  }

  &-img {
    @include media-breakpoint-down(medium) {
      margin-bottom: $grid-gap;
    }
  }

  &-title {
    @include h2;
  }
}
