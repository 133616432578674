.LocationsMap {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(medium) {
    background-position: 0 center;
    display: grid;
    grid-template-columns: 280px auto;
    grid-template-areas:
      'intro map'
      'filters map';
    min-height: calc(100vh - #{$header-height-large});
    overflow: hidden;
  }

  &-intro,
  &-filters {
    @include fluid-prop( margin-left margin-right, $grid-gap, $grid-gap * 2 );
  }
  
  &-intro {
    grid-area: intro;
    position: relative;
    padding-bottom: $grid-gap;
    @include fluid-prop( padding-top, $grid-gap * 1.5, $grid-gap * 3 );

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: -1;
      height: 110px;
      background: url('/src/assets/bg-pattern.png') 0 0 repeat;
    }
  }

  &-filters {
    grid-area: filters;
    align-self: end;
    padding-top: $grid-gap;
    @include fluid-prop( padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );
  }

  &-map {
    grid-area: map;
    background: $color-base;

    @include media-breakpoint-down(medium) {
      height: calc((var(--screen-height, 50vh) / 2) - #{$header-height-small});

      .has-overlay & {
        z-index: 1001;
        position: fixed;
        left: 0;
        right: 0;
        top: $header-height-small;
      }
    }
  }

  &-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    visibility: visible;
    pointer-events: none;
    
    &[hidden] {
      display: block;
      visibility: hidden;
    }

    @include media-breakpoint-up(medium) {
      position: absolute;
      top: 0;
      overflow: hidden;
    }
  }

  &-overlayCloseBtn {
    position: fixed;
    right: 0;
    top: $header-height-small;
    z-index: 1002;
    pointer-events: all;
    background: $color-highlight-1;
    color: #fff;
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(0);
    transition: visibility 0.3s ease, transform 0.3s ease, background 0.15s ease;

    [hidden] > & {
      visibility: hidden;
      transform: translateX(100%);
    }

    &:hover,
    &:active,
    &:focus {
      background: $color-highlight-2;
    }

    @include media-breakpoint-up(medium) {
      position: absolute;
      top: 0;
    }

    @include media-breakpoint-up(xlarge) {
      font-size: 24px;
      width: 84px;
      height: 84px;
    }
  }

  &-overlayInner {
    position: fixed;
    left: 0;
    right: 0;
    top: calc((var(--screen-height, 50vh) / 2));
    bottom: 0;
    pointer-events: all;
    background: #fff;
    z-index: 1001;
    transition: visibility 0.3s ease, transform 0.3s ease;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: auto;
    height: calc(var(--screen-height, 50vh) / 2);

    @include fluid-prop( padding-left padding-right, $grid-gap, $grid-gap * 2 );
    @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );

    [hidden] > & {
      display: block;
      visibility: hidden;
      transform: translateY(100%);
    }

    @include media-breakpoint-up(medium) {
      position: absolute;
      left: 0;
      right: auto;
      top: 0;
      bottom: 0;
      height: auto;
      width: 520px;
      box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);

      [hidden] > & {
        transform: translateX(-200%);
      }
    }
  }

  &-overlayDesc {
    margin-bottom: 1em;

    *:last-child {
      margin-bottom: 0;
    }
  }
}

.marker {
  $marker-border-color: rgba($color-base, 0.25);
  $original-height: 32px;
  $target-height: 30px;
  $target-height-active: 90px;
  $ratio: $target-height / $original-height;
  $ratio-active: $target-height-active / $original-height;

  background: svg-load('../assets/icons/location.svg', fill=#fff, stroke=$marker-border-color) 50% 50% no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: height 0.3s ease, width 0.3s ease;

  &[data-icon='location'] {
    background-image: svg-load('../assets/icons/location.svg', fill=#fff, stroke=$marker-border-color);
    width: 24px * $ratio;
    height: 32px * $ratio;
  }

  &[data-icon='power'] {
    background-image: svg-load('../assets/icons/power.svg', fill=#fff, stroke=$marker-border-color);
    width: 20px * $ratio;
    height: 32px * $ratio;
  }

  &[data-icon='leaf'] {
    background-image: svg-load('../assets/icons/leaf.svg', fill=#fff, stroke=$marker-border-color);
    width: 16px * $ratio;
    height: 32px * $ratio;
  }

  &[data-icon='flame'] {
    background-image: svg-load('../assets/icons/flame.svg', fill=#fff, stroke=$marker-border-color);
    width: 19px * $ratio;
    height: 32px * $ratio;
  }

  &[data-icon='cci'] {
    background-image: svg-load('../assets/icons/cci.svg', fill=$color-cci);
    width: 30px * $ratio;
    height: 19px * $ratio;
  }

  &.is-active {
    &[data-icon='location'] {
      background-image: svg-load('../assets/icons/location.svg', fill=$color-highlight-2);
    }

    &[data-icon='power'] {
      background-image: svg-load('../assets/icons/power.svg', fill=$color-highlight-2);
    }

    &[data-icon='leaf'] {
      background-image: svg-load('../assets/icons/leaf.svg', fill=$color-highlight-2);
    }
  
    &[data-icon='flame'] {
      background-image: svg-load('../assets/icons/flame.svg', fill=$color-highlight-2);
    }

    &[data-icon='cci'] {
      background-image: svg-load('../assets/icons/cci.svg', fill=$color-cci);
    }
  }

  &.is-large {
    &[data-icon='location'] {
      width: 24px * $ratio-active;
      height: 32px * $ratio-active;
    }
  
    &[data-icon='power'] {
      width: 20px * $ratio-active;
      height: 32px * $ratio-active;
    }
  
    &[data-icon='leaf'] {
      width: 16px * $ratio-active;
      height: 32px * $ratio-active;
    }
  
    &[data-icon='flame'] {
      width: 19px * $ratio-active;
      height: 32px * $ratio-active;
    }
  
    &[data-icon='cci'] {
      width: 30px * $ratio-active;
      height: 19px * $ratio-active;
    }
  }
}

// Mapbox overrides

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
  @include media-breakpoint-up(xlarge) {
    margin-right: 30px;
  }
}

.mapboxgl-ctrl button:not(:disabled) {
  transition: background 0.15s ease;

  &:hover {
    background: $color-highlight-1;
  }
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
  display: none;
}

.mapboxgl-ctrl-zoom-in {
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    height: 2px;
    width: 10px;
  }

  &::after {
    width: 2px;
    height: 10px;
  }
}

.mapboxgl-ctrl-zoom-out {
  position: relative;

  &::before {
    content: '';
    display: block;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 10px;
  }
}

.mapboxgl-popup-content {
  background: $color-highlight-2;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
  text-align: center;
  font-family: $font-family;
  padding: 15px;
  border-radius: 0;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: $color-highlight-2;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: $color-highlight-2;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: $color-highlight-2;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: $color-highlight-2;
}

.mapboxgl-ctrl-group {
  background: $color-base;
  border-radius: 0;

  button + button {
    border-top-color: $border-color-inverse;
  }
}
