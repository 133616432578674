.CtaStripBlock {
  position: relative;
  background: $color-base;
  padding: 0 $grid-gap;

  @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );
  @include fluid-prop( margin-top margin-bottom, $grid-gap * 1.5, $grid-gap * 3 );
  
  &:last-child {
    // Remove bottom gap when above footer
    @include fluid-prop( margin-bottom, -($grid-gap * 3), -($grid-gap * 1.5) );
  }

  @media print {
    display: none;
  }

  &-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.3;
  }

  &-inner {
    position: relative;
    z-index: 1;
    text-align: center;

    @include media-breakpoint-up(medium) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-heading {
    @include h3;

    color: #fff;
    margin-bottom: $grid-gap;

    @include media-breakpoint-up(medium) {
      margin-bottom: 0;
      margin-right: $grid-gap;
    }
  }
}