.Slider {
  width: 100%;

  &-inner {
    position: relative;
  }

  &-track {
    overflow: hidden;
  }
  
  &-slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    margin: 0;
  }
  
  &-slides--dragging {
    user-select: none;
  }
  
  &-slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  &-slide a {
    user-select: none;
  }
  
  &-arrows {
    -webkit-touch-callout: none;
    user-select: none;
  }
  
  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;

    &--prev {
      left: 20px;
    }

    &--next {
      right: 20px;
    }
  }

  &-bullets {
    -webkit-touch-callout: none;
    user-select: none;
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  &-bullet {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 8px solid transparent;
    background: $color-tint-3 content-box;
    transition: background 0.3s ease;

    &--active {
      background-color: $color-highlight-2;
    }
  }
}
