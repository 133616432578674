.FacetFilter {
  position: relative;

  & + & {
    border-top: 1px solid $color-tint-4;
  }

  &-input {
    position: absolute;
    opacity: 0;
  }

  &-icon {
    margin-right: 10px;
    font-size: 18px;
    color: $color-tint-3;
  }

  &-label {
    display: flex;
    font-size: $font-size-small;
    padding: 5px 0;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      transition: background 0.15s ease, border-color 0.15s ease;
      border-radius: 50%;
      border: 1px solid $border-color;
      margin-left: auto;
    }
  }

  &-input:checked + &-label::after {
    background: svg-load('../assets/icons/tick.svg', fill=#fff) no-repeat, var(--filter-color, $color-highlight-1);
    background-size: 10px;
    background-position: 50% 50%;
    border-color: var(--filter-color, $color-highlight-1);
  }
}
