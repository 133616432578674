/* *************************\
  Basic Modal Styles
\************************* */

.Modal {
  display: none;

  &.is-open {
    display: block;
  }
}

.Modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1004;
  will-change: transform;
  background: rgba($color-base, 0.66);
  display: flex;
  justify-content: center;
  padding: $grid-gap 0;
}

.Modal-container {
  background-color: #fff;
  width: 980px;
  max-width: 90%;
  max-height: calc(100vh - #{$grid-gap * 2});
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
}

.Modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 30px;
}

.Modal-title {
  @include h3;
  margin-top: 0;
  margin-bottom: 0;
}

.Modal-close {
  background: $color-highlight-1;
  color: #fff;
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(0);
  transition: visibility 0.3s ease, transform 0.3s ease, background 0.15s ease;
  margin-left: auto;

  &:hover,
  &:active {
    background: $color-highlight-2;
  }

  @include media-breakpoint-up(xlarge) {
    font-size: 24px;
    width: 84px;
    height: 84px;
  }

  svg {
    pointer-events: none;
  }
}

.Modal-content {
  @include fluid-prop( padding-left padding-right padding-bottom, $grid-gap, $grid-gap * 4 );
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

// Modifiers

/* *************************\
Animation Style
\************************* */

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.Modal--slide[aria-hidden='false'] .Modal-overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.Modal--slide[aria-hidden='false'] .Modal-container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.Modal--slide[aria-hidden='true'] .Modal-overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.Modal--slide[aria-hidden='true'] .Modal-container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.Modal--slide .Modal-container,
.Modal--slide .Modal-overlay {
  will-change: transform;
}

.Modal--carousel {
  .Modal-container {
    max-width: calc(100% - 90px);
  
    @include media-breakpoint-up(small) {
      max-width: calc(100% - 180px);
    }
  }

  .Modal-content {
    overflow: visible;
    padding: 0;
  }
}

.Modal--fitViewport {
  .Modal-overlay {
    padding: 0;
    background: rgba(#000, 0.75);
  }
  
  .Modal-container {
    width: 100%;
    height: 100%;
    background: transparent;
    max-width: calc(100% - $grid-gap);
    max-height: none;
    animation: none;
  
    @include media-breakpoint-up(xlarge) {
      max-width: calc(100% - 250px);
    }
  }

  .Modal-header {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .Modal-content {
    overflow: visible;
    padding: 0;
  }
}
