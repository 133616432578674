.ArticleAside {
  position: sticky;
  top: $grid-gap;
  align-self: start;
  padding: $grid-gap * 1.5 0 0;
  border-top: 1px solid $border-color;

  @include media-breakpoint-up(medium) {
    margin-left: $grid-gap * 3;
  }
}
