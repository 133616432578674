.SearchFacets {
  display: block;

  @include media-breakpoint-down(large) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1004;
    visibility: hidden;
    background: #fff;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.4s ease, visibility 0.4s ease;

    &.is-open {
      visibility: visible;
      transform: translateX(0);
    }
  }

  &-heading {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      font-size: 18px;
      color: $color-tint-2;
    }
  }

  &-filters {
    @include media-breakpoint-down(large) {
      flex: 1;
      height: 100%;
      padding: 60px $page-margin-positive 15px;
      overflow-y: auto;
      position: relative;
    }
  }

  &-close {
    @extend %ButtonLink;
    position: absolute;
    right: $page-margin-positive;
    top: 0;
    z-index: 1;
    padding: 20px 0;
  }

  &-apply {
    padding: 15px $page-margin-positive 30px;
  }
}
