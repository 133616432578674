.Lister {
  @include media-breakpoint-up(large) {
    display: grid;
    grid-template-columns: 3fr 1fr 8fr;
    grid-template-areas: 'filters . results';
  }

  &-results {
    grid-area: results;
  }

  &-resultsHeader {
    margin-bottom: 30px;

    @include media-breakpoint-up(large) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid $border-color;
      margin-bottom: 30px;
    }
  }

  &-resultsHeading {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
  }

  &-resultsFooter {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  &-toggleFacets {
    color: $color-highlight-1;
  }
}

@import './SearchBox.scss';
@import './SearchInfo.scss';
@import './SearchFacets.scss';