.Search {
  margin: $grid-gap * 1.5 0;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  &-input {
    margin-right: 20px;
  }
}
