.Card {
  position: relative;

  &-img {
    margin-bottom: $grid-gap;
  }

  &-meta {
    @extend %Meta;
    margin-bottom: 1em;
  }

  &-title {
    font-size: 21px;
  }

  &-link {
    color: inherit;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: $color-highlight-1;
    }

    &::before {
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &-body {
    *:last-child {
      margin-bottom: 0;
    }
  }
}
