.SearchInfo {
  display: flex;
  align-items: baseline;
  padding-bottom: 10px;
  border-bottom: 1px solid $border-color;

  @include fluid-prop(margin-bottom, 20px, 40px);

  &-heading {
    margin: 0 10px 0 0;
    max-width: 80%;
  }

  &-title {
    @include fluid-type( 22px, 26px);
    word-break: break-all;
  }

  &-titleLabel {
    font-size: 14px;
    font-weight: normal;
    color: $color-tint-1;
    display: block;

    @include media-breakpoint-up(large) {
      font-size: 26px;
      display: inline;
    }
  }

  &-count {
    @include fluid-type( 14px, 18px);
    color: $color-tint-1;
  }

  &-btn {
    @extend %ButtonLink;

    &--clear {
      margin-left: auto;

      @include media-breakpoint-up(large) {
        margin-left: 16px;
      }
    }

    &--all {
      @include media-breakpoint-down(large) {
        display: none;
      }
      
      @include media-breakpoint-up(large) {
        margin-left: auto;
      }
    }
  }
}