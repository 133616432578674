.LinkBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.LinkBlock-img {
  border: 0;
  border-right: 5px solid;
  border-image: $gradient-1 0 100%;
  margin-bottom: $grid-gap;
}

.LinkBlock-heading {
  @include h2;

  margin-bottom: 0.25em;

  &--h3 {
    @include h3;
  }

  &--h4 {
    @include h4;
    font-weight: 500;
  }
}

.LinkBlock-body {
  max-width: 440px;
  margin-bottom: auto;

  p {
    margin-bottom: 0.5em;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.LinkBlock-btn {
  margin-top: $grid-gap * 1.5;
}

//
// Modifiers
//

.LinkBlock--primary .LinkBlock-body {
  p:first-of-type:not(:last-of-type) {
    @extend %Lead;
    color: $color-base;
  }
}

.LinkBlock--nthBorderColor {
  &:nth-child(even) .LinkBlock-img {
    border-image: $gradient-2 0 100%;
  }
}

.LinkBlock--separator {
  border-top: 1px solid $border-color;
  @include fluid-prop( padding-top, $grid-gap * 1, $grid-gap * 2 );
}