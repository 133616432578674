
%ContentBlock,
.ContentBlock {
  position: relative;

  > p:first-child {
    @extend %Lead;
  }

  blockquote {
    @extend %blockquote;
  }
}
