.Error404 {
  $comp: &;

  @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );

  &-inner {
    @include container;
    @include fluid-prop( padding-top padding-bottom, $grid-gap, $grid-gap * 1.5 );

    position: relative;

    &::after {
      background: url('/src/assets/bg-pattern.png') 0 0 repeat;
      content: '';
      display: block;
      height: 100%;
      width: calc(#{$page-margin-positive} + #{percentage(3/12)});
      position: absolute;
      left: $page-margin-negative;
      top: 0;
      z-index: -1;
    }

    @include media-breakpoint-up(medium) {
      display: grid;
      gap: $grid-gap;
      grid-template-areas: 'image body';
      grid-template-columns: 6fr 6fr;
    }
  }

  &-imgContainer {
    border-color: $color-highlight-2;
    grid-area: image;
    margin-bottom: $grid-gap;
    position: relative;
    mask-image: url('/src/assets/cci-mask.svg');
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    mask-size: contain;

    &::before {
      background: url('/src/assets/bg-pattern-white.png') 0 0 repeat-y;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      opacity: 0.3;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    @include media-breakpoint-up(medium) {
      margin-bottom: 0;
    }
  }

  &:nth-child(odd) &-imgContainer {
    border-color: $color-highlight-1;
  }

  &-img {
    display: block;

    @include media-breakpoint-up(medium) {
      height: auto;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &-body {
    grid-area: body;

    p {
      margin-bottom: 0.5em;

      &:first-of-type:not(:last-of-type) {

        @extend %Lead;
        color: $color-base;
      }
    }

    @include media-breakpoint-up(medium) {
      @include fluid-prop( padding-top padding-bottom, $grid-gap * 2, $grid-gap * 4 );
    }
  }

  &-heading {
    margin-bottom: $grid-gap;
    margin-right: $page-margin-negative;
    padding-right: $page-margin-positive;
    border-bottom: 1px solid $border-color;
    @include fluid-type( 36px, 80px );
  }

  &-btn {
    margin-top: $grid-gap * 1.5;
  }
}
