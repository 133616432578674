.Facet {
  border-top: 1px solid $border-color;
  padding-top: $grid-gap;
  
  &:not(:last-child) {
    margin-bottom: $grid-gap;
  }

  // Non-collapsible version
  &-heading {
    font-size: $font-size-small;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 400;
    color: $color-tint-2;

    small {
      color: $color-tint-1;
      font-size: inherit;
    }
  }

  // Collapsible version
  &-btn {
    font-size: $font-size-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
  }

  &-btnIcon {
    color: $color-highlight-1;
    font-size: 10px;
  }

  &-dropdown {
    border-top: 1px solid $color-tint-4;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}