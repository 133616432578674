.Icon {
  display: inline-block;
  // stroke-width: 2px;
  // stroke: none;
  fill: currentColor;
  height: 1em;
  stroke: currentColor;
  vertical-align: -0.2em;
  width: 1em;
}
