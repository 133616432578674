.Pagination {
  @extend %list-unstyled;
  display: flex;
  font-size: 12px;

  &-item {
    margin: 0 6px;
  }

  &-link {
    &.is-current {
      color: $color-highlight-2;
    }

    &.is-disabled {
      pointer-events: none;
      color: $color-tint-3;
    }
  }
}