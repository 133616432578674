.Share {
  display: inline-flex;
  align-items: center;

  &-label {
    margin-right: 15px;
    margin-bottom: 0;
  }

  &-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  &-item {
    & + & {
      margin-left: 15px;
    }
  }

  &-link {
    color: $color-tint-2;
    font-size: 22px;
    opacity: 1;
    transition: color 0.3s ease;

    svg {
      display: block;
    }

    &:hover {
      color: $color-highlight-1;
    }
  }
}
