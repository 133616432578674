@mixin fluid-prop($properties, $min-value, $max-value ) {
  $min: min($min-value, $max-value);
  $max: max($min-value, $max-value);
  $val: '';

  @if $max-value < 0 {
    $val: strip-unit($min-value / $grid-container-width);
  }

  @else {
    $val: strip-unit($max-value / $grid-container-width);
  }

  @each $property in $properties {
    #{$property}: clamp(#{$min}, #{$val} * 100vw, #{$max});
  }
}

@mixin fluid-type( $min-value, $max-value ) {
  font-size: clamp(#{$min-value}, #{strip-unit($max-value / $grid-container-width)} * 100vw, #{$max-value});
}

// @mixin fluid-prop($properties, $min-value, $max-value, $min-vw: breakpoint("large"), $max-vw: breakpoint("xxlarge") ) {
//   @each $property in $properties {
//     #{$property}: $min-value;
//   }

//   @media (min-width: $min-vw) {
//     @each $property in $properties {
//       #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
//     }
//   }

//   @media (min-width: $max-vw) {
//     @each $property in $properties {
//       #{$property}: $max-value;
//     }
//   }
// }

// @mixin fluid-type( $minFontSize, $maxFontSize, $min-vw: breakpoint("large"), $max-vw: breakpoint("xxlarge") ) {
//   @include fluid-prop( font-size, $minFontSize, $maxFontSize, $min-vw, $max-vw );
// }
