.History {
  @include media-breakpoint-up(medium) {
    display: grid;
    grid-template-columns: 90px auto;
  }

  &-nav {
    list-style: none;
    position: sticky;
    display: flex;

    @include media-breakpoint-down(medium) {
      top: $header-height-small;
      z-index: 100;
      overflow-x: auto;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      background: #fff;
      padding: 0 $grid-gap;
      margin: 0 -$grid-gap;
    }

    @include media-breakpoint-up(medium) {
      top: $header-height-small + 20px;
      align-self: start;
      display: block;
      margin: 0;
      padding: 0;
    }

    @include media-breakpoint-up(xlarge) {
      top: 20px;
    }

    @media print {
      display: none;
    }
  }

  &-navLink {
    position: relative;
    display: block;
    padding: 10px 10px;
    color: $color-tint-2;

    &.is-active {
      color: $color-highlight-1;
    }

    @include media-breakpoint-up(medium) {
      padding: 10px 30px 10px 10px;
    }
  }

  &-timeline {
    position: relative;
    padding-top: 30px;
    min-width: 0;

    @include media-breakpoint-up(medium) {
      border-left: 1px solid $border-color;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 30px;
        bottom: 0;
        z-index: -1;
        width: 40px;
        background: url('/src/assets/bg-pattern.png') 0 0 repeat-y;
      }
    }
  }
}

.HistoryItem {
  padding: 0;
  position: relative;
  
  @include fluid-prop( padding-top padding-bottom, 40px, 70px );

  @include media-breakpoint-down(medium) {
    &:not(:first-child) {
      border-top: 1px solid $border-color;
    }

    @media screen {
      // Compensate for sticky nav
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: $header-height-small + 20px;
        bottom: 100%;
        left: 0;
      }
    }
  }

  @include media-breakpoint-up(medium) {
    @include fluid-prop( padding-left, 40px, 70px );
    border-top: 1px solid $border-color;
  }

  &-inner {
    @include media-breakpoint-up(medium) {
      display: flex;
    }
  }

  &-title {
    @include fluid-type( 36px, 70px );

    @include media-breakpoint-up(medium) {
      @include fluid-prop( padding-right, 20px, 70px );
    }
  }

  &-content {
    min-width: 0;
    display: block;
    flex: 1;
  }

  &-contentItem {
    & + & {
      margin-top: 50px;
    }

    @include media-breakpoint-up(medium) {
      border-left: 1px solid $border-color;
      @include fluid-prop( padding-left, 20px, 40px );
    }
  }

  &-figure {
    margin: 40px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-figureImage {
    border-right: 5px solid $color-highlight-1;
    margin-bottom: 10px;
  }

  &-figureCaption {
    color: $color-tint-2;
  }

  &-media {
    margin-top: 50px;
  }
}