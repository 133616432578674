.Footer {
  background: $color-base url('/src/assets/world-watermark.png') 0 0 / contain no-repeat;

  @include fluid-prop( margin-top, $grid-gap * 1.5, $grid-gap * 3 );
  @include fluid-prop( padding-top, $grid-gap * 1.5, $grid-gap * 3 );

  @include media-breakpoint-up(medium) {
    background-position: 0 center;
  }

  @media print {
    display: none;
  }

  &-inner {

    @include container;

    @include media-breakpoint-up(medium) {
      display: grid;
      gap: $grid-gap;

      grid-template-areas:
        'brand nav social'
        'smallprint smallprint smallprint';
      grid-template-columns: 4fr 7fr 1fr;
    }
  }

  &-brand {
    margin-bottom: 25px;
    width: 100px;

    @include media-breakpoint-up(medium) {
      grid-area: brand;
      width: 148px;
    }
  }
}

.FooterNav {

  @extend %list-unstyled;

  @include media-breakpoint-up(medium) {
    width: 100%;
    grid-area: nav;
    gap: $grid-gap;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &-item {
    display: inline-block;
    width: 100%;

    @include media-breakpoint-down(medium) {
      border-bottom: 1px solid rgba(#fff, 0.2);
    }
  }

  &-link {
    color: #fff;
    display: block;
    padding: 0.75em 0;
    transition: color 0.3s ease;

    &:hover {
      color: $color-highlight-1;
    }

    @include media-breakpoint-up(medium) {
      display: inline-block;
      margin-bottom: 0.5rem;
      padding: 0;
    }
  }

  &-subTitle {
    color: #fff;
    display: block;
  }

  &-sub {
    @extend %list-unstyled;
    margin-bottom: 0.5rem;
  }

  &-subTitle,
  &-sub {
    font-size: $font-size-small;
    
    @include media-breakpoint-down(medium) {
      display: none;
    }
  }

  &-subLink {
    color: #fff;
    opacity: 0.6;
    transition: color 0.3s ease, opacity 0.3s ease;

    &:hover {
      color: $color-highlight-1;
      opacity: 1;
    }
  }
}

.FooterSocial {
  align-self: flex-start;
  display: inline-flex;
  grid-area: social;
  justify-self: end;
  list-style: none;
  padding: 0;

  @include media-breakpoint-down(medium) {
    margin: 25px 0 60px;
  }

  &-item {
    & + & {
      margin-left: 15px;
    }
  }

  &-link {
    color: #fff;
    font-size: 22px;
    opacity: 1;
    transition: color 0.3s ease;

    svg {
      display: block;
    }

    &:hover {
      color: $color-highlight-1;
    }
  }
}

.FooterSmallPrint {
  border-top: 1px solid rgba(#fff, 0.15);
  font-size: 12px;
  padding: 30px 0;

  @include media-breakpoint-up(medium) {
    align-items: center;
    display: flex;
    grid-area: smallprint;
    justify-content: space-between;
  }

  &-copyright {

    @include media-breakpoint-up(medium) {
      margin-bottom: 0;
    }
  }

  &-nav {

    @extend %list-unstyled;
    display: flex;
    flex-wrap: wrap;
  }

  &-navItem {

    &:not(:last-child) {
      margin-right: $grid-gap;
    }
  }

  &-navLink {
    color: #fff;
    display: flex;
    opacity: 0.4;
    padding: 0.5em 0;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}
