.TabbedContentNav {
  list-style: none;
  padding: 0;
  margin: 0;

  &-item {
    border-bottom: 1px solid $border-color;
  }

  &-link {
    display: flex;
    font-size: $font-size-small;
    font-weight: 500;
    justify-content: space-between;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $color-highlight-1;
    width: 100%;
    padding: 15px 0;
  }

  &-item--active &-link {
    color: $color-highlight-2;
  }
}

.TabbedContent {
  $comp: &;

  &-heading {
    @extend %Meta;

    @include media-breakpoint-up(medium) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &-nav {
    @include media-breakpoint-up(medium) {
      position: relative;
      padding-right: calc(#{percentage(1/4)} - #{$grid-gap});
      
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: calc(#{percentage(0.5/4)} - #{$grid-gap});
        top: 0;
        height: 100%;
        width: 1px;
        background: $border-color;
      }
    }
  }

  &-nav,
  &-content {
    @include media-breakpoint-up(medium) {
      @include fluid-prop( padding-top, $grid-gap * 2, $grid-gap * 4 );
    }
  }

  &-content {
    position: relative;
  }

  &-track {
    overflow: hidden;
  }
  
  &-panels {
    position: relative;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    margin: 0;
    transform: translate3d(0, 0, 0) !important;
    width: 100% !important;
  }
  
  &-panels--dragging {
    user-select: none;
  }
  
  &-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    order: 2;
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &--active {
      position: relative;
      order: 1;
      opacity: 1;
      z-index: 1;
    }
  }
  
  &-panel a {
    user-select: none;
  }
  
  &-arrows {
    -webkit-touch-callout: none;
    user-select: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    @include media-breakpoint-up(medium) {
      @include fluid-prop( top, $grid-gap * 2, $grid-gap * 4 );
    }
  }
  
  &-arrow {
    color: $color-highlight-1;
  }
}
