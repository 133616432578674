$font-family: 'futura-pt', sans-serif;

$color-base: #0e2439;
$color-cci: #e2231a;

$color-tint-1: #5e6266;
$color-tint-2: #9ca4ac;
$color-tint-3: #d5dae0;
$color-tint-4: #f4f4f5;

$color-highlight-1: #5dbec5;
$color-highlight-2: #f07a1a;

$color-error: #f8184d;

$border-color: $color-tint-3;
$border-color-inverse: rgba(#fff, 0.3);

$gradient-1: linear-gradient(360deg, #5ccaff 0%, #45d5df 96.58%);
$gradient-2: linear-gradient(360deg, #f05b1a 0%, #f07a1a 95%);

$grid-container-width: 1120px;
$grid-gap: 20px;
$grid-breakpoints: (
  xsmall: 0,
  small: 576px,
  medium: 768px,
  large: 992px,
  xlarge: 1180px,
  xxlarge: 1400px
);

$grid-classes: (
  xsmall: 'xs',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  xlarge: 'xl',
  xxlarge: 'xxl'
);

$viewport-width: #{'( ( ( 100vw - var(--scrollbar-width ) ) - #{ $grid-container-width - ( $grid-gap * 2 ) } ) / 2 )'};
$page-margin-positive: #{'max( #{$viewport-width}, #{$grid-gap} )'};
$page-margin-negative: #{'min( -1 * #{$viewport-width}, #{-$grid-gap} )'};

$letter-spacing: 0.15em;

$font-size-base: 16px;
$font-size-large: 18px;
$font-size-small: 14px;

$header-height-small: 50px;
$header-height-large: 100px;
