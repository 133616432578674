.ImageBlock {
  $comp: &;

  margin: 0;

  &-img {
    position: relative;
    border-image: $gradient-2 0 100%;
    border-left-style: solid;
    border-left-width: 0;
    border-right-style: solid;
    
    .Section:nth-child(odd) & {
      border-image: $gradient-1 0 100%;
    }
  }

  &-caption {
    margin-top: 10px;
    margin-right: $grid-gap;

    @include media-breakpoint-up(medium) {
      position: absolute;
      left: 5px;
      bottom: 0;
      max-height: 100%;
      width: 100%;
      z-index: 2;
      background: linear-gradient(to bottom, rgba($color-base, 0), rgba($color-base, 1));
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: #fff;
      margin-top: 0;
      padding: 40px 0;
      padding-right: calc(100% - 275px - 60px);

      @include fluid-prop( padding-top padding-bottom, 30px, 60px );
      @include fluid-prop( padding-left, 20px, 60px );
    }
  }

  &-captionTitle {
    @include fluid-type( 18px, 44px );
    color: inherit;
    margin-bottom: 0.25em;
    max-width: 100%;
  }

  &-captionDesc {
    margin-bottom: 0;
  }

  // Modifiers

  &--hasWatermark {
    position: relative;

    &::before {
      background: url('/src/assets/cci-watermark-white.svg') 0% 50% / auto 100% no-repeat;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &--pullleft#{$comp}--hasWatermark {
    &::before {
      background-position: 0% 50%;
    }
  }

  &--pullright#{$comp}--hasWatermark {
    &::before {
      background-position: 100% 50%;
    }
  }

  &--pullboth {
    margin-left: $page-margin-negative;
    margin-right: $page-margin-negative;
  }

  &--pullleft {
    margin-left: $page-margin-negative;

    #{$comp}-img {
      border-left-width: 0;
      border-right-width: 5px;
    }

    #{$comp}-caption {
      right: 5px;
      left: auto;
    }
  }

  &--pullright {
    margin-right: $page-margin-negative;

    #{$comp}-img {
      border-left-width: 5px;
      border-right-width: 0;

      &::before {
        background: url('/src/assets/bg-pattern-white.png') 0 0 repeat-y;
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        opacity: 0.3;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    #{$comp}-right {
      left: 5px;
      right: auto;
    }
  }

  @media print {
    margin-left: 0;
    margin-right: 0;
  }
}
