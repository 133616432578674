.VideoPlayer {
  position: relative;
  height: 0;
  padding-bottom: percentage(9/16);
  border-right: 5px solid $color-highlight-2;
  background: $color-base;
  margin-bottom: 40px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: percentage(64/1064);
    top: 0;
    width: 1px;
    height: 100%;
    background: #fff;
    opacity: 0.65;
  }

  @media print {
    display: none;
  }

  iframe,
  &-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &-thumb {
    object-fit: cover;
    opacity: 0.35;
  }

  &-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-transform: uppercase;
    color: #fff;
    font-size: 11px;
    letter-spacing: 0.05em;

    &::before {
      @include fluid-prop( width height, 60px, 100px );
      @include fluid-prop( margin-bottom, 15px, 30px );

      content: '';
      display: block;
      margin-left: auto;
      margin-right: auto;
      border: 2px solid $color-highlight-2;
      border-radius: 50%;
      background: svg-load('../assets/icons/play.svg', fill=#fff) 50% 50% / 30% auto no-repeat;
    }

    &:hover::before {
      border-color: $color-highlight-1;
    }
  }
}