.ArticleList {
  display: grid;
  grid-auto-rows: auto;
  column-gap: $grid-gap;
  row-gap: $grid-gap * 1.5;
  margin-bottom: $grid-gap * 1.5;
  
  @include media-breakpoint-up(medium) {
    grid-template-columns: 1fr 1fr;
  }

  &-item {
    border-bottom: 1px solid $border-color;
    padding-bottom: $grid-gap * 1.5;

    &--hero {
      border-bottom: 0;
      padding-bottom: 0;
      grid-column: 1 / -1;
    }
  }
}
