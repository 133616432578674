.Event {
  display: flex;
  align-items: center;
  background: $color-tint-4;
  margin-bottom: $grid-gap;
  border-left: 3px solid $color-highlight-1;

  &-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    border-right: 1px solid $border-color;

    @include fluid-prop( width, 60px, 90px );
  }

  &-day {
    @include h4;
    margin: 0;
  }

  &-month {
    font-size: 12px;
    letter-spacing: 0.3em;
    font-weight: 500;
    text-transform: uppercase;
  }

  &-inner {
    flex: 1;
    display: flex;
    padding: $grid-gap 0;
    
    @include fluid-prop( padding-left padding-right, $grid-gap, $grid-gap * 1.5 );
    
    @include media-breakpoint-down(small) {
      flex-direction: column;
      justify-content: center;
    }

    @include media-breakpoint-up(small) {
      align-items: center;
      justify-content: space-between;
    }
  }

  &-body {
    @include media-breakpoint-up(small) {
      padding-right: $grid-gap;
      flex: 1;
    }
  }

  &-title {
    @include h4;
    margin-bottom: 0.25em;
  }

  &-location {
    font-size: 16px;
    margin-bottom: 0.25em;

    @include media-breakpoint-up(small) {
      margin: 0;
    }
  }
}
