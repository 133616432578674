.ModalCarousel {
  $comp: &;

  margin-top: -50px;
  padding-top: 50px;

  @include media-breakpoint-up(xlarge) {
    margin-top: -84px;
    padding-top: 84px;
  }

  &-inner {
    position: relative;
  }

  &-track {
    overflow: hidden;
  }
  
  &-slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
  }
  
  &-slides--dragging {
    user-select: none;
  }
  
  &-slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  &-slide a {
    user-select: none;
  }
  
  &-arrows {
    -webkit-touch-callout: none;
    user-select: none;
  }
  
  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    
    @include fluid-prop(font-size, 24px, 60px );

    &--prev {
      @include fluid-prop(left, -$grid-gap, -$grid-gap / 2 );
      transform: translate(-100%, -50%);
    }

    &--next {
      @include fluid-prop(right, -$grid-gap, -$grid-gap / 2 );
      transform: translate(100%, -50%);
    }
  }

  &-nav {
    position: absolute;
    bottom: 4vh;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }

  &-navItem {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 5px;
    background: $color-tint-2;

    &--active {
      background: $color-highlight-1;
    }
  }

  // Modifiers

  &--inverse {
    #{$comp}-nav {
      border-color: $border-color-inverse;
    }

    #{$comp}-navItem:not(#{$comp}-navItem--active) {
      border-color: $border-color-inverse;
      color: #fff;
    }
  }

  &--fitViewport {
    margin-top: 0;
    padding-top: 0;

    #{$comp}-arrows {
      @include media-breakpoint-down(xlarge) {
        position: absolute;
        bottom: 4vh;
        left: 0;
        right: 0;
        height: 8px;
        z-index: 1;
        pointer-events: none;
      }
    }

    #{$comp}-arrow {
      @include media-breakpoint-down(xlarge) {
        pointer-events: all;
        transform: translateY(-50%);
        font-size: 24px;

        &--prev {
          left: 0;
        }
    
        &--next {
          right: 0;
        }
      }
    }
  }
}
