.SearchResults {
  margin: 0 auto;
  max-width: 940px;

  @include media-breakpoint-up(large) {
    padding: 0 50px;
    max-width: 1000px;
  }

  &-heading {
    @include h3;
  }

  &-count {
    font-size: 12px;
    vertical-align: super;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: results;
  }

  &-listItem {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    display: grid;
    gap: $grid-gap;
    grid-template-columns: 1fr 11fr;
    grid-template-areas: 'counter body';

    @include fluid-prop(padding-bottom, $grid-gap, $grid-gap * 1.5);
    @include fluid-prop(padding-top, $grid-gap * 1.5, $grid-gap * 2);

    & + & {
      margin-top: -1px;
    }

    @include media-breakpoint-up(large) {
      grid-template-columns: 2fr 6fr 2fr;
      grid-template-areas: 'counter body .';
    }
  }

  &-listIndex {
    grid-area: counter;
    counter-increment: results;
    font-size: 12px;
    color: $color-base;
    font-weight: normal;
  }
}
