.GalleryCarousel {
  $comp: &;

  margin: $grid-gap * 2 (-$grid-gap);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s ease, visibility 0.15s ease;

  @include media-breakpoint-up(medium) {
    margin-right: 0;
    margin-left: 0;
  }

  &-inner {
    position: relative;
  }

  &-track {
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  &-slides {
    position: relative;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    margin: 0;
  }
  
  &-slides--dragging {
    user-select: none;
  }
  
  &-slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  &-slide a {
    user-select: none;
  }

  &-link {
    position: relative;
    display: block;
  }

  &-slideOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(#000, 0.5);
    font-size: 22px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.15s ease, opacity 0.15s ease;
  }

  &-link:hover &-slideOverlay {
    opacity: 1;
    visibility: visible;
  }
  
  &-arrows {
    -webkit-touch-callout: none;
    user-select: none;
    position: absolute;
    bottom: 0;
    left: $grid-gap;
    right: $grid-gap;
    height: 20px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(medium) {
      right: 0;
      left: 0;
    }
  }
  
  &-arrow {
    color: $color-highlight-1;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    pointer-events: all;

    @include fluid-type(12px, 14px);

    &--prev {
      left: 0;

      #{$comp}-arrowIcon {
        margin-right: 4px;
      }
    }

    &--next {
      right: 0;

      #{$comp}-arrowIcon {
        margin-left: 4px;
      }
    }
  }

  &-nav {
    display: flex;
    justify-content: center;
  }

  &-navItem {
    display: block;
    width: 20px;
    height: 20px;
    padding: 6px;
    letter-spacing: 0.05em;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: $color-tint-3;
    }

    &--active::before {
      background: $color-highlight-1;
    }
  }

  // Modifiers

  &--init {
    visibility: visible;
    opacity: 1;
  }

  &--inverse {
    #{$comp}-nav {
      border-color: $border-color-inverse;
    }

    #{$comp}-navItem:not(#{$comp}-navItem--active) {
      border-color: $border-color-inverse;
      color: #fff;
    }
  }
}

.GalleryImage {
  position: relative;
  width: 100%;
  height: 84vh;
  margin: 8vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-img {
    object-fit: contain;
    object-position: 50% 50%;
    display: block;
    width: 100%;
    max-height: 100%;
    min-height: 0;
  }

  &-caption {
    padding-top: 20px;
    color: #fff;
    text-align: center;
  }
}