.Cover {
  $comp: &;

  @include fluid-prop( padding-top padding-bottom, $grid-gap * 1.5, $grid-gap * 3 );

  &-inner {
    @include container;
  }

  &-hero {
    grid-area: hero;
  }

  &-imgContainer {
    border-right: 5px solid $color-highlight-1;
    bottom: 0;
    left: $page-margin-negative;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    &::before {
      background: url('/src/assets/cci-watermark-white.svg') 0 50% / auto 100% no-repeat;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    &::after {
      background: linear-gradient(to bottom, rgba($color-base, 0) 40%, rgba($color-base, 0.8) 100%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }

    @media print {
      display: none;
    }
  }

  &-img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: var(--img-position, center);
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-hgroup {
    position: relative;

    @include fluid-prop( margin-bottom, $grid-gap, $grid-gap * 1.5 );
  }

  &-teaser {
    color: $color-tint-2;
    display: block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: $letter-spacing;
    margin-bottom: $grid-gap;
    text-transform: uppercase;
  }

  &-heading {
    margin: 0;

    @include media-breakpoint-up(medium) {
      padding-right: percentage(1/12);
    }
  }

  &-desc {
    p:first-child {
      @extend %Lead;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  // Modifiers

  &--hasImage {
    padding-top: 0;
    padding-bottom: 0;

    @include fluid-prop( margin-bottom, $grid-gap * 1.5, $grid-gap * 3 );

    #{$comp}-hero {
      @media screen {
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: calc(75vh - #{$header-height-small});
        @include fluid-prop( padding-bottom, $grid-gap, $grid-gap * 1.5 );
      }

      @include media-breakpoint-up(xlarge) {
        min-height: calc(66vh - #{$header-height-large});
      }
    }

    #{$comp}-hgroup {
      padding-right: $grid-gap;
      padding-bottom: 0;
      
      @include fluid-prop( margin-top, $grid-gap, $grid-gap * 2 );

      @include media-breakpoint-up(medium) {
        padding-right: percentage(1.5/12);
      }
    }

    #{$comp}-teaser,
    #{$comp}-heading {
      color: #fff;
    }

    #{$comp}-teaser {
      border-bottom: 1px solid $border-color-inverse;
      margin-bottom: $grid-gap;
      margin-left: $page-margin-negative;
      padding-bottom: $grid-gap;
      padding-left: $page-margin-positive;

      @media print {
        border-bottom-color: $border-color;
      }
    }
  }

  &--hasImage:not(#{$comp}--hasDescription) {
    margin-bottom: 0;
  }

  &--hasDescription {
    #{$comp}-inner {
      column-gap: $grid-gap;
      display: grid;
      grid-template-areas:
        'hero hero'
        'intro intro';
      grid-template-columns: 8fr 4fr;

      @include media-breakpoint-up(medium) {
        grid-template-areas:
          'hero hero'
          'intro control';
      }
    }

    #{$comp}-hero {
      grid-area: hero;
      margin-bottom: $grid-gap * 1.5;
    }

    #{$comp}-desc {
      grid-area: intro;
    }

    #{$comp}-body {
      grid-column-start: 1;
      grid-column-end: 3;
      @include fluid-prop( margin-top, $grid-gap * 2, $grid-gap * 3 );
    }
  }

  &--hasDescription:not(#{$comp}--hasImage) {
    #{$comp}-hgroup {
      border-bottom: 1px solid $border-color;
      margin-bottom: 0;
      margin-left: $page-margin-negative;
      padding-bottom: $grid-gap * 1.5;
      padding-left: $page-margin-positive;
    }
  }

  &--isFullBleed &-imgContainer {
    right: $page-margin-negative;
  }
}
